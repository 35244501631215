import React, { Component } from 'react';
import * as Util from './../../data/General';



class CheckboxTemplate extends Component {


    componentDidMount() {
        
    }

    onChange = () => {
        const { onBlur, onBlurExtra, extra, row } = this.props;
                
        if (onBlur){
            onBlur(!row.selected, row.id);
        }else if (onBlurExtra){
            onBlurExtra(!row.selected, row.id, extra);
        }
    }

    render() {
        const { row, template, disabled, planActive } = this.props;
        return (
            <div key={row.id}>
                <label className={"form-check-conteiner " + (row.id === template.name ? 'active' : '') + (disabled ? ' disabled' : '')}>{row.label && row.label}
                    {disabled && row.id !== template.name && (
                        <div className='text-disabled'>
                            {Util.getMessage("PlanSupperX").replace("{{1}}", planActive)}
                        </div>
                    )}
                    <input type="checkbox" name={row.name} id={row.id} onChange={() => this.onChange()} 
                        value={row.name} checked={row.id === template.name} disabled={row.disabled}
                    /> 
                    <span className={`form-check-span`}></span>
                    <div className="name">{Util.getMessage(row.name)}</div>

                    <div className='title-check'>{Util.getMessage(row.menssagetitle1)}</div>
                    <div className='text-check' style={row.isCustom ? {whiteSpace:"pre-wrap"} :{}} dangerouslySetInnerHTML={{__html: row.isCustom ? (template.message ? template.message : Util.getMessage("tempCustomAdd")) + "<br/><br/>"+Util.getMessage("tempCustom_text1") : Util.getMessage(row.menssagetext1)}} />

                </label>
                    
            </div>
        );
  }
}

export default CheckboxTemplate;