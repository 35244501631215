import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { connect } from 'react-redux';
import App from './../../App';
import Dashboard from '../../pages/Dashboard';
import Plan from '../../pages/Plan';
import Reviews from '../../pages/Reviews';
import Config from '../../pages/Config';
import * as Constants from './../../store/index';
import Presentation from '../../pages/Presentation';
import  * as Util from './../../data/General';
import Loader from '../../pages/Loader';
import mixpanel from 'mixpanel-browser';
import Products from '../../pages/Products';
import Analytics from '../../pages/Analytics';
import Campaigns from '../../pages/Campaigns';
import CampaignsCheck from '../../pages/CampaignsCheck';
import FrequentQuestions from '../../pages/FrequentQuestions';
import AnalyticsCampaigns from '../../pages/AnalyticsCampaigns';
import Flow from '../../pages/Flow';
import Hearings from '../../pages/Hearings';
import ConfigWhatsapp from '../../pages/ConfigWhatsapp';


class DefaultLayoutUnic extends Component {

  state ={
    login : "",
    option:"home",
    shop: null,
    isInstalled: false,
    isload: true,
    login:{
      step: 0,
      install: false,
      shopId: ''
    }
  }

  componentDidMount() {
    //Util.getInfoShop(this.handleOkShop, this.handleOkShopError);
    var shop = Util.getAdminShop();
    if (shop){
      let isInstalled = false;
      
      var installAdmin = localStorage.getItem(Constants.storage.USER_INSTALL_ADMIN);
      //console.log("installAdmin", installAdmin);
      if (installAdmin){
        installAdmin = JSON.parse(installAdmin);
        if (installAdmin && (installAdmin.install || installAdmin.step > 3)){
          this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: '/home'
          });
          this.setState({login: installAdmin});
        }else{
          isInstalled= true;
          this.setState({login: installAdmin});
        }
      }else{
        var install = localStorage.getItem(Constants.storage.USER_INSTALL);
        if (install && install === "false"){
          this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: '/home'
          });
        }
      }
      mixpanel.init(window.mixpanelKey); 
      mixpanel.track('AD Dashboard', {
        'shopDomain': shop.domain,
        'shopId': shop.id,
      });
      //console.log("isInstalled", isInstalled);
      if(isInstalled){
        this.setState({isload: false, isInstalled: isInstalled, shop: shop});
      }else{
        this.setState({isload: false, isInstalled: isInstalled});
      }
      
    }
  }

  handleOkShop = (data) => {
    console.log(data);
    if (data && data.id){
      var shop =  Util.getAdminShop();
      if (shop){
        localStorage.setItem(Constants.storage.USER_NAME, data.name);
        localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        window.location.href = '/';
        this.props.dispatch({
          type: 'CHANGE_MENU',
          new_option: '/home'
        });
        /*mixpanel.init(window.mixpanelKey); 
        mixpanel.track('Sign up', {
          'shopDomain': data.domain,
          'shopId': data.id,
        });*/
        Util.setLanguage(data);


        let isInstalled = false;

        var installAdmin = localStorage.getItem(Constants.storage.USER_INSTALL_ADMIN);
        if (installAdmin){
          installAdmin = JSON.parse(installAdmin);
          if (installAdmin && (installAdmin.install || installAdmin.step > 3)){
            this.props.dispatch({
              type: 'CHANGE_MENU',
              new_option: '/home'
            });
            this.setState({login: installAdmin});
          }else{
            isInstalled= true;
          }
        }
        isInstalled = this.checkDateShop(data, installAdmin);
        this.setState({shop: data, isInstalled: isInstalled, isload: false});
        
        
      }else{
        //console.log("install");
        this.setState({shop: data, isInstalled: true, isload: false});
        localStorage.setItem(Constants.storage.USER_NAME, data.name);
        localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        /*mixpanel.init(window.mixpanelKey); 
        mixpanel.track('INSTALL', {
          'shopDomain': data.domain,
          'shopId': data.id,
        });*/
        Util.setLanguage(data);

        this.checkDateShop(data, null);
        
      }
    }
  }

  checkDateShop = (shop, installAdmin) => {
    const d = new Date();
    let myArray = shop.creationDate.split("T");
    let creationDate = new Date(myArray[0]);
    let date_hoy = new Date();
    let difference = date_hoy.getTime() - creationDate.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let isInstalled = false;
    if (TotalDays > 2){
      console.log("mas de dos dias");
      isInstalled= false;
      let login={
        step: 4,
        install: true,
        shopId: shop.id
      };
      localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
    }else{
      if(installAdmin && installAdmin.shopId !== shop.id){
        //console.log("distinto tienda");
        isInstalled= true;
        let login={
          step: 0,
          install: false,
          shopId: shop.id
        };
        localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
      }
    }
    return isInstalled;
  }

  handleOkShopPlan = (data) => {
    console.log(data);
    if (data && data.id){
        this.setState({shop: data, isInstalled: false, isload: false});
        localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        window.location.href = '/';
        this.props.dispatch({
          type: 'CHANGE_MENU',
          new_option: '/plan'
        });
    }
  }

  handleOkShopError = (data) => {
    console.log(data);
  }
  

  render() {
    let { menuSelected, location } = this.props;
    const { isInstalled, shop, isload, login } = this.state;
    //console.log("menuSelected",menuSelected);

    //console.log("isInstalled",(shop && isInstalled));

    if (!shop && location && location.search.startsWith('?accesstoken=')){
      if (location.search !== "?accesstoken=ERROR"){
        let text = location.search.replace('?accesstoken=', '');
      
        if(location.search.includes("install=yes")){
          //localStorage.clear();
          text = text.replace('&install=yes', '');
        }
        text = text.split("&shop=");

        localStorage.setItem(Constants.storage.USER_ACCESS, text[0]);
        Constants.setAccessTokenStore(text[0]);
        Util.getInfoShop(this.handleOkShop, this.handleOkShopError);
      }
      
      //window.location.href = "/";
      //menuSelected = "/presentation";
    }else if (location && location.search.startsWith('?price=')){
        Util.getInfoShop(this.handleOkShopPlan, this.handleOkShopError);
    }else if (location && location.search.startsWith('?check-whatsapp=true')){
      menuSelected = "/campaigns-check-whatsapp";
    }else if (location && location.search.startsWith('?campaigns')){
      menuSelected = "/campaigns";
    }else if (shop && isInstalled ) {
      if((shop.plan.planType !== "0" || shop.shopType !== "SHOPIFY")){
        menuSelected = "/presentation";
      }else if(menuSelected === "/presentation"){
        window.location.href = '/';
        this.props.dispatch({
          type: 'CHANGE_MENU',
          new_option: '/plan'
        });
      }
      //localStorage.setItem(Constants.storage.USER_INSTALL, true);
    }
    console.log("menuSelected",menuSelected);
    return (
      <Route>
        {((isload && !shop ) && !(menuSelected === "/campaigns-check-whatsapp"))? (
          <App isMenu={false}>
              <Loader />
            </App>
        ) : menuSelected === "/presentation" && shop && shop.id ? (
            <App isMenu={false}>
              <Presentation shop={shop} login={login} />
            </App>
          ): (
            <App>
              {menuSelected === "/home" ? (
                <Dashboard shop={shop} login={login}/>
              ) : menuSelected === "/reviews" ? (
                <Reviews />
              ) : menuSelected === "/analytics-products" ? (
                <Products />
              ) : menuSelected === "/analytics" ? (
                <Analytics />
              ) : menuSelected === "/config" ? (
                <Config />
              ) : menuSelected === "/plan" ? (
                <Plan />
              ) : menuSelected === "/campaigns" ? (
                <AnalyticsCampaigns />
              ) : menuSelected === "/campaigns-check-whatsapp" ? (
                <CampaignsCheck checkFacebook={true} />
              ) : menuSelected === "/campaigns-check" ? (
                <CampaignsCheck />
              ) : menuSelected === "/campaigns-check-normal" ? (
                <Campaigns type="BASIC"/>
              ) : menuSelected === "/campaigns-check-flow" ? (
                <Flow />
              ) : menuSelected === "/campaigns-check-normal-hearings" ? (
                <Hearings />
              ) : menuSelected === "/config-whatsapp" ? (
                <ConfigWhatsapp />
              ) : menuSelected === "/frequentquestions" && (
                <FrequentQuestions />
              )}

            </App>
          )}
      </Route>
  );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  menuSelected: state.menu.menuSelected
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayoutUnic);