import React, { Component } from 'react';
import * as Util from './../../data/General';

class Sentiment extends Component {

    render() {
        const { sentiment } = this.props;

        return (
            <div className={`sentiment-content ${sentiment}`}>
                {Util.getMessage(`sentiment_${sentiment}`)}
            </div>
        );
  }
}

export default Sentiment;