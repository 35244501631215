import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './../components/auth/Auth';
import  * as Util from './../data/General';

import { connect } from 'react-redux';

class Login extends Component {
  componentDidMount() {
    const { user, shop } =this.props;
    /*if (user.idToken && user.idToken != null && shop && shop.length > 0){
      window.location.href = '/home';
    }
    Util.addScrollBody();*/
  }

  handleClick = () => {
    window.location.href = '/home';
  }

  render() {
    return (
      <div className="login" onClick={() => this.handleClick()}>
        <h2 className="frame-1">Revie</h2>
          {/*<h2 className="frame-1">Triciclo Go.</h2>
          <h2 className="frame-5">
            <span>Logistica</span>
            <span>&</span>
            <span>Envios</span>
          </h2>
          <button className="btn btn-dark" onClick={auth0Client.signIn}>Ingresar</button>
          <img src="/img/shopify.png" alt="logo shopify" />/*/}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
    user: state.user,
    shop: state.shop,
});
  
const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login));
