import React, { Component } from 'react';

import toast, { Toaster } from 'react-hot-toast';


class Notify extends Component {

    state = { 
        text: ""
    };

    componentDidMount() {   
        const { text } = this.props;
        setTimeout(function(){
            this.notify(text);
        }.bind(this), 100);
    }

    /*componentWillReceiveProps = (nextProps)  => {
        if(nextProps.text !== this.state.text){
            this.notify(nextProps.text);
        }
    }*/

    notify = (text) => {
        this.setState({text: text});
        toast(text, {
            duration: 8000,
            position: 'bottom-center',
        
            // Styling
            style: {},
            className: 'tost-content',
        
            // Custom Icon
            icon: '👏',
        
            // Change colors of success/error/loading icon
            iconTheme: {
            primary: '#000',
            secondary: '#fff',
            },
        
            // Aria
            ariaProps: {
            role: 'status',
            'aria-live': 'polite',
            },
        });
    }

    render() {
        
        return (
            <Toaster 
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
              
                  // Default options for specific types
                  success: {
                    duration: 3000,
                    theme: {
                      primary: 'green',
                      secondary: 'black',
                    },
                  },
                }}
              />
        );
  }
}


  
  export default (Notify);