import { useState, useEffect } from 'react'
import * as Util from '../data/General';

export function useStatistics ({urlInicial, callback = null, server="marketing/campaigns/statusstatistics"}) {
  const [data, setData] = useState(null);
  const [url, setURL] = useState(urlInicial);
  const [isLoad, setIsLoad] = useState(false);

  const refreshUrl = (url) => {
    setURL(url);
  }

  const refreshData = () => {
    setIsLoad(true);
    Util.getStatistics(dataOk, dataFail, `${server}${url}`);
  }
  const dataOk = (data) => {
    setData(data);
    if(callback) {
      callback(data);
    }
    setIsLoad(false);
  }
  const dataFail = (data) => {
    console.log(data);
  }

  // para recuperar la cita al cargar la página
  useEffect(() => {
    refreshData(urlInicial)
  }, [url])

  return { data, isLoad, refreshUrl }
}