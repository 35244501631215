import React, { Component } from 'react';
import Review from './Review';
import * as Util from './../../data/General';
import Button from '../html/Button';
import { connect } from 'react-redux';
import Notify from '../html/Notify';

class ListReviews extends Component {

    state ={
        notify: ""
    }

    handleOk = () => {
        const { handleOk } = this.props;

        if (handleOk) {
            handleOk();
        }
    }

    goToPedding = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/reviews"
        });
    }

    openModal = (url, text, type="img") => {
        let modal = document.getElementById("myModal");
        if(modal){
            modal.remove();
        }
        document.body.insertAdjacentHTML('afterbegin', `<div id="myModal" class="modal">
            <span class="close">&times;</span>
            <div id="component-modal" class="content-flex flex-center"></div>
            <div id="caption"></div>
        </div>`);
        
        modal = document.getElementById("myModal");
        // Get the image and insert it inside the modal - use its "alt" text as a caption
        //var modalImg = document.getElementById("img01");
        var modalImg = document.getElementById("component-modal");
        var captionText = document.getElementById("caption");
        var header = document.getElementsByClassName("header")[0];
        var menu = document.getElementsByClassName("menu")[0];
        
        modal.style.display = "block";
        header.style.zIndex=0;
        menu.style.zIndex=0;
        if(type === "img"){
            modalImg.innerHTML = `<img class="modal-content" src="${url}" id="img01"/>`;
        }else{
            modalImg.innerHTML = `<video controls autoplay>
            <source src="${url}" type="video/mp4"  />
            Your browser does not support the video element.
        </video>`;
        }
        captionText.innerHTML = text;
        
        

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks on <span> (x), close the modal
        span.onclick = function() { 
            modal.style.display = "none";
            modal.remove();
            header.style.zIndex=99;
            menu.style.zIndex=99;
        }
    }

    updateImage = (review) => {
        if (review && review.product && review.product.id){
            Util.updateImage(review.product.id);
        }
    }

    render() {
        const { reviews, title, isShowPoints, titleHtml=false, isShowTitle=true, isPay } = this.props;
        const { notify } = this.state;
        return (
            <div className='content-reviews'>
                {isShowTitle && (
                    <div className='title'>
                        {titleHtml ? (
                            <div className='content-flex btton-left'>
                                <span dangerouslySetInnerHTML={{__html: titleHtml }} />
                                <Button value={Util.getMessage("show_all")} type="primary" onClick={() => this.goToPedding()}/>
                            </div>
                        ) : (
                            <span>{Util.getMessage(title)}</span>
                        )}
                    </div> 
                )}
                
                {reviews && reviews.length > 0 ? reviews.map((item, key) => (
                    <div key={key}>
                        <Review review={item} isPay={isPay} updateImage={this.updateImage} isShowPoints={isShowPoints} handleOk={() => this.handleOk()} openModal={this.openModal}/>
                    </div>
                )) : (
                    <div className='sR'>{Util.getMessage("WITHOUT")} {Util.getMessage(title)}</div>
                )}

                {notify !== "" && (
                    <Notify text={notify} />
                )}
            </div> 
        );
  }
}

const mapStateToProps = (state) => ({
    menuSelected: state.menu.menuSelected,
    menuShow: state.menu.menuShow,
    isMobile: state.system.isMobile,
    shop: state.shop
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListReviews);
