import React, { useEffect } from 'react';
import Point from '../html/Point';

function StarProduct(props) {
 const { product, className, showEmpty = "-", isShowPoints=false} = props;

 
  useEffect(() => {
    
  });

  return (
        <>
            {product.reviewData && product.reviewData.avgProductStars && product.reviewData.avgProductStars > 0 ? (
                <div className={`cont-stars ${className ? className : ''}`}>
                    <div className='content-flex flex-center lh25 g8px'>{isShowPoints ? (parseFloat(product.reviewData.avgProductStars).toFixed(2)) : ''} <Point point={product.reviewData.avgProductStars} /></div>
                    {product.reviewData.countProduct} Reviews
                </div>
            ) : (
                <div className='cont-stars'>{showEmpty}</div> 
            )}
        </>
    );
}
export default StarProduct;