import React, { Component } from 'react';

class Step extends Component {

    render() {
        const { isLoader, isCheck, key, isError } = this.props;

        return (
            <div className={`step-item ${isLoader ? 'loader' : ''} ${isCheck ? 'ckeck' : ''} ${isError ? 'error-message' : ''}`} key={key}>
                {this.props.children}
                {isLoader ? (
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                ) : isCheck ? (
                    <div className='icon'>&#10003;</div>
                ) : isError ? (
                    <div className='icon'>&#10060;</div>
                ) : null}
            </div>     
        );
  }
}

export default Step;