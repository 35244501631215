import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Constants from './../store/index';

//import auth0Client from './auth/Auth';

class Header extends Component {

  state ={
    name: ""
  }
  componentDidMount() {
    var retrievedObject = localStorage.getItem(Constants.storage.USER_NAME);
    if (retrievedObject){
      this.setState({name: retrievedObject.replace('.myshopify.com', '')});
    }
  }

  handleClickMenu = () => {
    this.props.dispatch({
      type: 'CHANGE_SHOW_MENU'
    });
    return false;
  }   
  signOut = () => {
    this.props.dispatch({
      type: Constants.types.LOGIN,
      idToken: null,
      profile: null,
      expiresAt: null,
  });
  //auth0Client.signOut();
}
goToHome = () => {
  this.props.dispatch({
    type: 'CHANGE_MENU',
    new_option: '/home'
  });
}

  render() {
    const { shop, user } = this.props;
    const { name } = this.state;
    //if (user && user.idToken){MEREQUETENGUE
      return (
        <div className="header">
          <div className='logo' onClick={() => this.goToHome()}><img src="img/logo.png"/></div>
          <div className='shop-name'>{name && name !== "undefined" ? name : ''}</div>
        </div>
      );
    /*}else {
      return <span></span>;
    } */
  }
}

const mapStateToProps = (state) => ({
  shop: state.shop,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
