import React, { useState, useEffect } from 'react';
import * as Util from './../../data/General';
import StarProduct from './StarProduct';
import Button from '../html/Button';
import StarList from './StarList';
import ListReviews from './ListReviews';
import Page from '../html/Page';

export const ADDPOSITIVE = 3;
export const ADDNEGATIVE = -3;
export const MAX_WIDTH = 30;

function ProductHook(props) {
  const [style, setStyle] = useState({
    width: 40,
    height: 40
    });

    const [add, setadd] = useState(ADDPOSITIVE);
  const [count, setCount] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [filter, setFilter] = useState({
    fromDate: "",
    toDate: "",
    text: "",
    from: 0,
    size: 15,
    sortBy: "createdAt",
    sortOrder: "desc",
    state:"PENDING_APPROVAL,APPROVED,REJECTED",
  });

  const { product, isShowProduct, show, hide, isPay, planIA } = props;

  const showEfectCount = () => {
    setTimeout(function(){
        setStyle({
            width: style.width + add,
            height: style.height + add
        });
        setCount(count + 1);
    }.bind(this), 1);
    }

    const setHide = () => {
        setadd(ADDNEGATIVE);
        setCount(0);
    }

    const handleOk = (data) => {
        setReviews(data);
    }
    
    const handleError = (data) => {
        console.log(data);
    }

    const goTo = (from) => {
        filter.from = from;
        setFilter(filter);
        Util.getReviews(filter, handleOk, handleError);
    }

    const handleChangePage = (page) => {
        filter.size = page;
        setFilter(filter);
        Util.getReviews(filter, handleOk, handleError);
    }

    const goToPedding = () => {
        props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/plan"
        });
    }

    

  useEffect(() => {
    if(isShowProduct && count < MAX_WIDTH){
        showEfectCount();
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDPOSITIVE){
        setIsShow(true);
        filter.productId=product.productId;
        setFilter(filter);
        Util.getReviews(filter, handleOk, handleError);
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDNEGATIVE){
        hide();
        setadd(ADDPOSITIVE);
        setCount(0);
        setIsShow(false);
    }
  },[count, isShowProduct]);

  return (
    <>
        {isShow && (
            <div className='link-ext-product'>
                <Button type="link" value={Util.getMessage("back_hide")} imageInc="img/back.png" onClick={() => setHide()}/>
            </div>
        )}
        <div onClick={() => {show(product)}} className={`content-review content-product-item ${isShowProduct ? 'show-unic' : ''}`} >
            <div className='content-img-prod'>
                {product.images && product.images.length > 0 ? (
                        <img src={Util.getUrlImageWidth(product.images[0].src)} 
                            loading="lazy"
                            alt="img product"
                            style={{width: style.width+'px', height: style.height+'px'}}
                            onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = 'img/error.png';
                            }} 
                        />
                    ) : (
                        <img 
                            loading="lazy"
                            alt="img product"
                            src='img/error.png' 
                        />
                    )}
                    <div>
                        {product.name}
                        {isShow && (
                            <>
                                <StarProduct product={product} className="inline" showEmpty="" isShowPoints={true}/>
                                <a className='link-product' href={`https://${Util.getShopDomain()}/admin/products/${product.productId}`}  target="_blank">
                                    <div>
                                        <img 
                                            loading="lazy"
                                            alt="img product"
                                            src='img/enlace.png' 
                                        /> {product.name}
                                    </div>
                                </a>
                            </>
                        )}
                    </div>
            </div>
            {!isShow && (
                <div className='content-flex flex-center'>
                    <div>{product.reviewData && product.reviewData.avgProductStars && product.reviewData.avgProductStars > 0 ? (parseFloat(product.reviewData.avgProductStars).toFixed(2)) : ''}</div>
                    <StarProduct product={product}/>
                </div>
            )}
        </div>
        {isShow && (
                <div>
                    <StarList id={product.productId} />
                    
                    {planIA ? (
                        <>
                            {product.reviewData.tags && product.reviewData.tags.length > 0 && (
                                <div className='content-tags'>
                                    <div className='title-tags'>{Util.getMessage("Tags")}</div>
                                    <div className='tags'>
                                        {product.reviewData.tags.map((tag, key) => (
                                            <div key={key} className='tag-product'>
                                                <img 
                                                    loading="lazy"
                                                    alt="img tag"
                                                    src='img/etiqueta.png' 
                                                /> {tag}
                                            </div>
                                        ))}
                                    </div>
                                    <small className='help' dangerouslySetInnerHTML={{__html: Util.getMessage("help-plan-IA-1")}}></small>
                                </div>
                            )}
                            {product.reviewData.summary && (
                                <div className='content-tags'>
                                    <div className='title-tags'>{Util.getMessage("Summary")}</div>
                                    <div className='text-tags'>{product.reviewData.summary}</div>
                                    <small className='help' dangerouslySetInnerHTML={{__html: Util.getMessage("help-plan-IA-2")}}></small>
                                </div>
                            )}
                            {product.reviewData.problems && Util.isLengthSpace(product.reviewData.problems[0]) && (
                                <div className='content-tags'>
                                    <div className='title-tags'>{Util.getMessage("problems")}</div>
                                    <ul className='problem-list'>
                                        {product.reviewData.problems.map((problem, key) => (
                                            <li key={key}>
                                                {problem}
                                            </li>
                                        ))}
                                    </ul>
                                    <small className='help' dangerouslySetInnerHTML={{__html: Util.getMessage("help-plan-IA-3")}}></small>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className='update-plan-IA'>
                            <div style={{"background": "green","color": "#fff","padding": "8px", borderRadius: "20px 2px",marginTop: "2rem"}}><p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-0")}}></p> </div> 
                            <div className='title-update-plan'>1) {Util.getMessage("problems")}</div>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-1")}}></p> 
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-2")}}></p> 
                            <hr className='internal'/>
                            <div className='title-update-plan'>2) {Util.getMessage("Summary")}</div>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-3")}}></p>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-4")}}></p>
                            <hr className='internal'/>
                            <div className='title-update-plan'>3) {Util.getMessage("Tags")}</div>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-5")}}></p>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-6")}}></p>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-7")}}></p>
                            <hr className='internal'/>
                            <div className='title-update-plan'>4) {Util.getMessage("update-plan-widget")}</div>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("update-plan-IA-8")}}></p>
                            <img src="img/imagen.png" />
                            <p>
                                <div className='update-plan-name'><a href="#" onClick={() => goToPedding()}>{Util.getMessage("update_plan")}</a></div>
                            </p>
                            <hr className='internal'/>
                        </div>
                    )}

                    <div className='content-tags'>
                        <div className='title-tags'>{Util.getMessage("reviews")}</div>
                        <ListReviews reviews={reviews} isPay={isPay} isShowTitle={false} title={"reviews"}/> 
                        {reviews && reviews.length > 0 && (
                            <Page from={filter.from} size={filter.size} elementSize={reviews.length} handleClick={goTo} handleChangePage={handleChangePage}/>
                        )}  
                    </div>
                </div>
            )}
    </>
);
}

export default ProductHook;