import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import AppRoutes from './routes';
//import 'bootstrap/dist/css/bootstrap.css';
import store from './store/index';
//import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from './serviceWorker';
import * as Config from './config/config';
/*
render(
    <Auth0Provider
        domain={Config.AUTH.domain}
        clientId={Config.AUTH.client_id}
        redirectUri={window.location.origin}
    >
        <Provider store={store}>
            <Router>
                <AppRoutes /> 
            </Router>
        </Provider>
    </Auth0Provider>, 
document.getElementById('root'));*/

render(
    
    <Provider store={store}>
        <Router>
            <AppRoutes /> 
        </Router>
    </Provider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
