import React, { useState, useEffect } from 'react';
import CardTemplate from './CardTemplate';

function WhatsApp(props) {
    
    const { message, click, isSelected, isName = false, isError, } = props;
    const {body, footer, mediaurl, buttontext, name, mediatext="",cards} = message;

    const onclick = () =>{
       if(click){
        click(message);
       } 
    }

    const format_text =(text) => {
        let format_text = text.replaceAll(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
             .replaceAll(/(?:_)([^_<\n]+)(?:_)/g, "<i>$1</i>")
              .replaceAll(/(?:\~)([^~<\n]+)(?:\~)/g, "<s>$1</s>")
              .replaceAll(/(?:```)([^```<\n]+)(?:```)/g, "<tt>$1</tt>")
              .replaceAll(/\r?\n/g, "<br>");
        if(isError){
            let form = [/<br><br><br>/g, /<\/i><i>/g, /<\/s><s>/g, /<\/tt><tt>/g, /<\/strong><strong>/g];
            let cou =[0,0,0,0,0];
            let men = null;
            for (let index = 0; index < form.length; index++) {
                const element = form[index];
                var count = (format_text.match(element) || []).length;
                if(count > cou[index]){
                    men = "error_whastsapp_"+(index+1);
                }
            }
            isError(men);
        }

        return format_text;

    }
    let cardsWA = cards;
    if(cardsWA && !Array.isArray(cardsWA)){
        cardsWA = JSON.parse(cardsWA);
    }
    return (
        <>
            <div className={`wp ${isSelected ? 'active' : ''}`} onClick={() => onclick()}>
                {isName ? (<div className='name'>{name}</div>) : null}
                {!cards ? (
                    <>
                    {mediatext === "" ? (
                        <div className='img'>{mediaurl && mediaurl !== "" ? 
                        (mediaurl.includes(".mp4") ? <video controls>
                        <source src={mediaurl} type="video/mp4"></source></video> : <img src={mediaurl}/>) 
                        : <div className='img-black'></div>}</div>
                    ) : (<div className='headerText'>{mediatext}</div>)}
                    </> 
                ) : null}
                <div className='body' dangerouslySetInnerHTML={{__html: format_text(body)}} />
                {!cards ? (
                <>
                    <div className='footer'>{footer}</div>
                    <div className='button-wp'><img src="/img/linkwp.png" />{buttontext}</div>
                </>
                ) : null}
            </div>
            {cardsWA && Array.isArray(cardsWA) ? (
                <div className='create-wa-cards'>
                    { cardsWA.map((item, key) => (
                        <div className={`wp ${isSelected ? 'active' : ''}`} onClick={() => onclick()}>
                            <div className='img'>{item.media !== "" ? 
                            (item.media.includes(".mp4") ? <video controls>
                            <source src={item.media} type="video/mp4"></source></video> : <img src={item.media}/>) 
                            : <div className='img-black'></div>}</div>
                            <div className='headerText'>{item.title}</div>
                            <div className='body' dangerouslySetInnerHTML={{__html: format_text(item.body)}} />
                            <div className='button-wp'><img src="/img/link.png" />{item.actions[0].title}</div>
                       </div>
                       
                    ))}
                </div>
            ) : null}
        </>
    );
}
    
export default WhatsApp;
