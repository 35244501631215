import React, { Component } from 'react';
import SelectHtml from './SelectHtml';
import * as Util from './../../data/General';

class Page extends Component {

    state = { 
        value: "",
    };

    componentDidMount() {
        
    }

    previus = () => {
        const { from, handleClick } = this.props;
        if (from !== 0 && handleClick){
            handleClick(from-1);
        }
    }

    following = () => {
        const { from, size, elementSize ,handleClick } = this.props;
        if (elementSize === parseInt(size) && handleClick){
            handleClick(from+1);
        }
    }

    handleChange = (value, name) =>{
        const { handleChangePage } = this.props;
        if (handleChangePage){
            handleChangePage(value);
        }
        
    }

    render() {
        const { from, size, elementSize } = this.props;
        
        return (
            <div className="pagination">
                <div className={from === 0 ? "disabled" : ""} onClick={() => this.previus()}><i className="icofont-hand-drawn-left"></i></div>
                <div className="no-Class"><SelectHtml edit={true} options={Util.pagination} onChange={this.handleChange} name="type" value={size}/></div> 
                <div className={parseInt(size) !== elementSize ? "disabled" : ""} onClick={() => this.following()}><i className="icofont-hand-drawn-right"></i></div>
            </div>
        );
  }
}

export default Page;