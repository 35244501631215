import { createStore } from 'redux';
import auth0Client from './../components/auth/Auth';
import message from './../components/message/list.json';
export const URL_API = window.url_server;

export const types = {
  CHANGE_NAME: "CHANGE_NAME",
  CHANGE_LIST_ORDENS: "CHANGE_LIST_ORDENS",
  CHANGE_PAGE_ORDENS: "CHANGE_PAGE_ORDENS",
  CHANGE_MENU :"CHANGE_MENU",
  CHANGE_SHOW_MENU :"CHANGE_SHOW_MENU",
  CHANGE_FILTER :"CHANGE_FILTER",
  CLICK_ORDEN :"CLICK_ORDEN",
  CLICK_PACKAGE :"CLICK_PACKAGE",
  CHANGE_LIST_SHOPS: "CHANGE_LIST_SHOPS",
  CHANGE_LIST_SHIPPINGS: "CHANGE_LIST_SHIPPINGS",
  CHANGE_SHIPPING: "CHANGE_SHIPPING",
  RESIZE: "RESIZE",
  CHANGE_CHART_BAR: "CHANGE_CHART_BAR",
  CHANGE_CHART_DONUT: "CHANGE_CHART_DONUT",
  CHANGE_CHART_STAGES: "CHANGE_CHART_STAGES",
  CHANGE_LIST_EMPAQUES : "CHANGE_LIST_EMPAQUES",
  CLICK_EMPAQUE : "CLICK_EMPAQUE",
  LOGIN: "LOGIN",
  LOGIN_SHOPIFY: "LOGIN_SHOPIFY",
  LOGIN_LOCAL: "LOGIN_LOCAL",
  LOGIN_SHOP: "LOGIN_SHOP",
  LOGIN_TYPE: "LOGIN_TYPE",
  CHANGE_LIST_PICKUPS: "CHANGE_LIST_PICKUPS",
  CHANGE_CONFIG: "CHANGE_CONFIG",
  CHANGE_DASHCARDS : "CHANGE_DASHCARDS",
  CLICK_STAGES: "CLICK_STAGES",
  CHANGE_STAGES: "CHANGE_STAGES",
  CHANGE_LIST_RETURNS: "CHANGE_LIST_RETURNS",
  CHANGE_LIST_RETURNS_SELECTED: "CHANGE_LIST_RETURNS_SELECTED"
};

export const storage = {
  ORDENS_LIST: "ordens-list",
  ORDEN: "orden",
  PACKAGE: "package",
  SHOPS_LIST: "shops-list",
  VERSION: "version",
  SHIPPINGS_LIST: "shippings-list",
  CHART_BAR: "chart-bar",
  CHART_DONUT: "chart-donut",
  LOGIN: "login",
  SHOP: "shop",
  SHIPPING: "shipping",
  EMPAQUES_LIST: "empaques-list",
  PICKUPS_LIST: "pickups-list",
  CHANGE_CONFIG: "config",
  STAGES: "stages",
  RETURNS: "RETURNS",
  USER_ACCESS: "login-access",
  USER_NAME: "login-name",
  USER_SHOP: "login-shop",
  USER_INSTALL: "login-install",
  USER_SOCIAL_CONFIG: "login-social-config",
  USER_INSTALL_ADMIN: "login-install-admin",

};

export const status = {
  PAYMENT_CONFIRMED: "Guía creada",
  IN_TRANSIT: "En transito",
  DELIVERED: "Entregado",
  CANCELED: "Cancelado",
  PICKUP: "Esperando recolección"
};

export const status_icon = {
  PAYMENT_CONFIRMED: "icofont-mail",
  IN_TRANSIT: "icofont-delivery-time",
  DELIVERED: "icofont-box",
  CANCELED: "icofont-close-circled",
  PICKUP: "icofont-delivery-time"
};

export const status_returns = {
  PENDING: "Pendiente",
  
};

export const status_order = {
  pending: {__html:'<span class="_21Z9T _3o7e3 _33uWB"><span class="_5QJiT"></span><span class="-EFlq"><span class="_5QJiT"></span></span>Pago pendiente</span>'},
  paid: {__html:'<span class="_21Z9T _1EPFc"><span class="-EFlq"><span class="_5QJiT"></span></span>Pagado</span>'},
};

export var getFecha = function(num) {
  if (num > 9){
    return num;
  }else{
    return "0"+num;
  }
}

// Hoy
let day= new Date();
let today = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());
//Ayer
day.setDate(day.getDate() - 1);
let yesterday = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());



//Semana pasada
day.setDate(day.getDate() - 6);
let lastWeek = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());

day= new Date();
day.setMonth(day.getMonth() - 1);
let lastMonth = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());

//Mañana
day= new Date();
day.setDate(day.getDate() + 1);
let tomorrow = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());

day= new Date();
day.setFullYear(day.getFullYear() - 1);
let lastYear = day.getFullYear()+"-"+getFecha(day.getMonth()+1)+"-"+getFecha(day.getDate());

export const initialConfig = {
  version: "0.0.01",
  useWeight: "false",
  insuredCargo: "false",
  addressesDefault: -1, 
  sizeOrdens: 15,
  sizeshippings: 15, 
  isSeeBalance: true,
  autoPickup: "true"
}

let config = localStorage.getItem(storage.CHANGE_CONFIG);
if (config){
  config = JSON.parse(config);
  if (!config.hasOwnProperty("version") || initialConfig.version !== config.version){
    Object.entries(initialConfig).map(([key,value])=>{
      if (!config.hasOwnProperty(key)) { 
        config[key] = value;
      }
    });
    localStorage.setItem(storage.CHANGE_CONFIG, JSON.stringify(config));
  }
}else{
  config = initialConfig;
}

export const addressSelect = {
  name: "name",
  street1: "street1",
  city: "city",
  zip: "zip",
};

export const addressNew = {
  city: "",
  country: "Mexico",
  countryCode: "MX",
  name: "",
  state: "",
  street1: "",
  street2: "",
  zip: "",
};

export const filter = {
  fromDate: "",
  toDate: "",
  text: "",
  from: 0,
  size: 20,
  sortBy: "createdAt",
  sortOrder: "desc",
  state:"",
  selectedDate: null
};


export const initialState = {
  version: "0.0.1",
  config: config,
  system:{
    windowWidth:0,
    windowHeight: 0,
    isMobile: false
  },
  shops: {
    list:[],
    filter:{
      text: "",
      from: 0,
      size: 10,
      sortBy: "createdAt",
      sortOrder: "desc",
    }
  },
  shop: {
  },
  user: {
  },
  menu: {
    menuShow: true,
    menuSelected: "/home"
  },
  today: today,
  yesterday: yesterday,
  lastWeek: lastWeek,
  lastMonth: lastMonth,
  tomorrow: tomorrow,
  lastYear: lastYear,
  filter: filter,
};

let initialStateStorage = initialState;

let retrievedObject = localStorage.getItem(storage.VERSION);
if (retrievedObject !== initialStateStorage.version){
  localStorage.clear();
  localStorage.setItem(storage.VERSION, initialStateStorage.version);
  console.log("clear");
}else{
  try{
    /*retrievedObject = localStorage.getItem(storage.LOGIN);
    if (retrievedObject){
      initialStateStorage.user = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.SHOP);
    if (retrievedObject){
      initialStateStorage.shop = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.CHART_BAR);
    if (retrievedObject){
      initialStateStorage.chart_bar = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.CHART_DONUT);
    if (retrievedObject){
      initialStateStorage.chart_donut = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.ORDENS_LIST);
    if (retrievedObject){
      initialStateStorage.ordens = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.SHIPPINGS_LIST);
    if (retrievedObject){
      initialStateStorage.shippings = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.PICKUPS_LIST);
    if (retrievedObject){
      initialStateStorage.pickups.list = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.ORDEN);
    if (retrievedObject){
      initialStateStorage.orden = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.SHIPPING);
    if (retrievedObject){
      initialStateStorage.shipping = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.PACKAGE);
    if (retrievedObject){
      initialStateStorage.packageList.selected = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.SHOPS_LIST);
    if (retrievedObject){
      initialStateStorage.shops.list = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.EMPAQUES_LIST);
    if (retrievedObject){
      initialStateStorage.packageList.list = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.DASH_DATA);
    if (retrievedObject){
      initialStateStorage.dashData.list = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.STAGES);
    if (retrievedObject){
      initialStateStorage.stages.list = JSON.parse(retrievedObject);
    }
    retrievedObject = localStorage.getItem(storage.RETURNS);
    if (retrievedObject){
      initialStateStorage.returns = JSON.parse(retrievedObject);
    }*/
  }catch (e){
    console.log(e);
  }
}

//initialState.menu.menuSelected(window.location.pathname);
//https://680060809aed2e507183b423c39d2a05:6af178b14f1b2b9426a7b5d88718f9bd@chenson-temp.myshopify.com/admin/api/2019-04/orders.json?limit=50&page=1

const reducer = (state = initialStateStorage, action) => {
  console.log(action);
  //if (action.type &&  action.page === 1) localStorage.setItem("state", JSON.stringify(state));
  switch (action.type) {
    case types.CHANGE_NAME:
      return { ...state, name: "carlitos" };
    case types.CHANGE_LIST_EMPAQUES:{
      state = { ...state, packageList:{ ...state.packageList, list:action.list}};
      localStorage.setItem(storage.EMPAQUES_LIST, JSON.stringify(state.packageList.list)); 
      return state;
    }
    case types.CHANGE_CONFIG:{
      state = { ...state, config: action.config};
      localStorage.setItem(storage.CHANGE_CONFIG, JSON.stringify(state.config)); 
      console.log(state);
      return state;
    }
    case types.CLICK_EMPAQUE :{
      state =  { ...state, package: action.empaque }; 
      return state;
    }
    case types.CLICK_STAGES :{
      state =  { ...state, stages: { ...state.stages, list:action.list} }; 
      localStorage.setItem(storage.STAGES, JSON.stringify(action.list));
      return state;
    }
    case types.CHANGE_CHART_BAR:{
      state = { ...state, chart_bar:{ ...state.chart_bar, options:{...state.chart_bar.options, xaxis: {...state.chart_bar.options.xaxis, categories:action.labels}}, series:[{name: 'Ordenes', data: action.series}]}}; 
      localStorage.setItem(storage.CHART_BAR, JSON.stringify(state.chart_bar));
      return state;
    }  
    case types.CHANGE_CHART_DONUT:{
      state = { ...state, chart_donut:{ ...state.chart_donut, options:{...state.chart_donut.options, labels:action.labels}, series:action.series}}; 
      localStorage.setItem(storage.CHART_DONUT, JSON.stringify(state.chart_donut));
      return state;
    } 
    case types.CHANGE_CHART_STAGES:{
      state = { ...state, chart_stages:{ ...state.chart_stages, options:{...state.chart_stages.options, labels:action.labels}, series:action.series}}; 
      return state;
    } 
    case types.LOGIN:{
      state = { ...state, user:{ ...state.user, idToken: action.idToken, profile:action.profile , expiresAt:action.expiresAt, accessToken: action.accessToken}}; 
      localStorage.setItem(storage.LOGIN, JSON.stringify(state.user));
      return state;
    } 
    case types.LOGIN_SHOPIFY:{
      state = { ...state, user: action.user}; 
      localStorage.setItem(storage.LOGIN, JSON.stringify(state.user));
      return state;
    }  
    case types.LOGIN_LOCAL:{
      state = { ...state, user:{ ...state.user, data: action.data}}; 
      localStorage.setItem(storage.LOGIN, JSON.stringify(state.user));
      return state;
    } 
    case types.LOGIN_TYPE:{
      state = { ...state, user:{ ...state.user, type: action.data}}; 
      localStorage.setItem(storage.LOGIN, JSON.stringify(state.user));
      return state;
    } 
    case types.LOGIN_SHOP:{
      state = { ...state, shop: action.data}; 
      localStorage.setItem(storage.SHOP, JSON.stringify(state.shop));
      return state;
    } 
    case types.CHANGE_LIST_SHIPPINGS:{
      state = { ...state, shippings:{ ...state.shippings, list:action.list, filter:action.filter} }; 
      localStorage.setItem(storage.SHIPPINGS_LIST, JSON.stringify(state.shippings));
      return state;
    }
    case types.CHANGE_LIST_PICKUPS:{
      state = { ...state, pickups: { ...state.pickups, list:action.list} };
      localStorage.setItem(storage.PICKUPS_LIST, JSON.stringify(state.pickups.list));
      return state;
    }
    case types.CHANGE_LIST_RETURNS:{
      state = { ...state, returns: { ...state.returns, list:action.list} };
      localStorage.setItem(storage.RETURNS, JSON.stringify(state.returns));
      return state;
    }
    case types.CHANGE_LIST_RETURNS_SELECTED:{
      state = { ...state, returns: { ...state.returns, selected:action.selected} };
      localStorage.setItem(storage.RETURNS, JSON.stringify(state.returns));
      return state;
    }
    case types.CHANGE_LIST_SHOPS:{
      state = { ...state, shops:{ ...state.shops, list:action.shops, filter:action.filter} }; 
      if (action.filter === initialState.shops.filter) localStorage.setItem(storage.SHOPS_LIST, JSON.stringify(state.shops.list));
      return state;
    }
    case types.CLICK_ORDEN:
      state =  { ...state, orden: action.orden }; 
      localStorage.setItem(storage.ORDEN, JSON.stringify(state.orden)); 
      return state;
    case types.CLICK_PACKAGE:
      state =  { ...state, packageList:{ ...state.packageList, selected: action.selected} }; 
      localStorage.setItem(storage.PACKAGE, JSON.stringify(state.packageList.selected)); 
      return state;  
    case types.CHANGE_FILTER:
      return { ...state, filter: action.filter }; 
    case types.RESIZE:
      return { ...state, system:{ ...state.system, windowWidth:action.windowWidth, windowHeight:action.windowHeight, isMobile:action.isMobile} };   
    case types.CHANGE_SHOW_MENU:
      return { ...state, menu: { ...state.menu, menuShow: !state.menu.menuShow} }; 
    case types.CHANGE_MENU:
      return { ...state, menu: { ...state.menu, menuSelected: action.new_option} };
    case types.CHANGE_SHIPPING:
      state =  { ...state, shipping: action.shipping }; 
      localStorage.setItem(storage.SHIPPING, JSON.stringify(state.shipping)); 
      return state;  
    case types.CHANGE_DASHCARDS:{
      state = { ...state, dashData:{ count:action.count, costAvg:action.costAvg, deliveryDaysAvg:action.deliveryDaysAvg, costSum:action.costSum}}; 
      localStorage.setItem(storage.DASH_DATA, JSON.stringify(state.dashData));
      return state;
    }       
  }
  return state
}

export var getDate = function(varDate) {
  if(varDate && typeof varDate === 'string'){
    let arr = varDate.split("T");
    let day = arr[1].split("-");
    let min = day[0].split(":");
    
    if (initialState.today === arr[0]){
      let element = 'Today ';
      if (window.paramRevie.lenguage == 'es'){
        element = 'Hoy ';
      }else if (window.paramRevie.lenguage == 'pt'){
        element = 'Hoje ';
      }
      return element + min[0]+":"+ min[1];
    } else if (initialState.yesterday === arr[0]){
      let elementYesterday = 'Yesterday ';
      if (window.paramRevie.lenguage == 'es'){
        elementYesterday = 'Ayer ';
      }else if (window.paramRevie.lenguage == 'pt'){
        elementYesterday = 'Ontem ';
      }
      return elementYesterday + min[0]+":"+ min[1];
    }
    return arr[0] +" " + min[0]+":"+ min[1];
  }
  return "";
}

export var getDateWithoutTime = function(varDate) {
  if(varDate && typeof varDate === 'string'){
    let arr = varDate.split("T");
    let day = arr[0].split("-");
    /*if (initialState.today === arr[0]){
      return "Hoy";
    } else if (initialState.yesterday === arr[0]){
      return "Ayer";
    }*/
    return day[1] + "-" +day[2];
  }
  return "";
}

export var setAccessTokenStore = function(accessTS = null) {
  if (accessTokenStore === null){
    accessTokenStore =  accessTS;
  }
}
export var getAccessTokenStore = function() {
  if (accessTokenStore === null){
    accessTokenStore =  localStorage.getItem(storage.USER_ACCESS);
  }
  return accessTokenStore;
}
var accessTokenStore = null;
export var getHeaders = function(addHeader = null) {
  if (accessTokenStore === null){
    accessTokenStore =  localStorage.getItem(storage.USER_ACCESS);
  }
  
  if(accessTokenStore !== null && accessTokenStore !== ""){
    if (addHeader === null){
      return {
        headers: {
          'Authorization': `Bearer ${accessTokenStore}`
        }
      };
    }else{
      addHeader.Authorization = `Bearer ${accessTokenStore}`;
      return {
        headers: addHeader
      };
    }
  }else{
    //window.location.href = "/?accesstoken=ERROR";
    return {};
  }
}
export var setErrors = function(error) {
  if(error.response){
    if (error.response.status === 401) {
      //No logueado
      /*console.log("Error 401");
      //window.location.href = "/";
      store.dispatch({
        type: types.LOGIN,
        idToken: null,
        profile: null,
        expiresAt: null,
      });
      auth0Client.signOut();*/
      alert("401");
      window.location.href = "/login";
    }else if (error.response.status === 500) {
      console.log("500");
      console.log(store);
      retrievedObject = localStorage.getItem(storage.SHOP);
      console.log(retrievedObject);
      if (retrievedObject === '""' || retrievedObject === null){
        //window.location.href = "/preferences";
      }
      //window.location.href = "/server-error";
    }else if (error.response.status === 503) {
      // reiniciando el server
      //window.location.href = "/out-server";
    }
  }else if ("Error: Network Error" == error){
    //window.location.href = "/out-server";
  }
}


export var getMessage = function(text) {
  if (message.hasOwnProperty(text)) {
      return message[text];
  }else{
    if(text ){
      try{
        let jsonError = JSON.parse(text);
        if (jsonError.message && message.hasOwnProperty(jsonError.message)) {
          return message[jsonError.message];
        }
      } catch (e) {
        return "Server Error: " + text;
      }
    }else{
      return "Server Error";
    }
  }
  return text;
}

export var scrollToWindow = function() {
  document.getElementById("content").scrollTo(0, 0);
}

export var isCancelDateForDay = function (createDate, day) {
  /*const today = moment(Date());
  today.format('l');
  const newDate = moment(createDate);
  newDate.format('l');
  var difDate = (today.diff(newDate , 'days'));
 return difDate >= day;*/
}


export var getFilter = function(filter) {
  let filterText="";
  Object.entries(filter).map(([key,value])=>{
    if (value !== "" && value !== null){
      if(filterText === ""){
        filterText="?"+key+"="+value;//Cambiar despues
      }else{
        filterText+="&"+key+"="+value;
      }
    }
  })
  return filterText;
};


const store = createStore(reducer, 
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;