import React, { Component } from 'react';
import * as Util from './../../data/General';


class HideShow extends Component {

    state = { value: ""};

    componentDidMount() {
        
    }

    render() {
        const { active, type, index, onclick, className } = this.props;

        return (
            <div className={`content-hidden-show ${className ? className : ""} `} onClick={() => onclick(index, type)} title={Util.getMessage(active === false ? "show" : "hide")+" "+Util.getMessage(type)}>
                {active === false ? (
                    <img src='img/hidden.png' alt="hidde"/>
                ) : (
                    <img src='img/show.png' alt="show" />
                )}
            </div>
        );
  }
}

export default HideShow;