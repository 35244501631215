import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import Button from './Button';
import * as Util from './../../data/General';

class RememberProm extends Component {

    render() {
        const { costMens, cancelCampaing, newOk } = this.props;

        return (
            <>
                <div className='text-prom'>
                    <div dangerouslySetInnerHTML={{__html: Util.getMessage("remember_1")}} />
                    <div dangerouslySetInnerHTML={{__html: Util.getMessage("remember_2")}} />
                    {costMens > 0 ? <label>{Util.getMessage("remember_3")}<b><CurrencyFormat value={costMens*0.3} decimalScale={2} decimalSeparator="," thousandSeparator={"."} displayType={'text'} prefix={'$'} /></b> (*)</label> : null}
                    <label style={{fontSize: "13px"}}><small>{Util.getMessage("confirm_campaign_7")}</small></label>
                </div>
                <div className={`text button_campaign ${cancelCampaing ? "t2" : ""}`}>
                    <Button value={Util.getMessage("remember_button")}  type="primary" onClick={() => newOk()}/>
                    {cancelCampaing ? (<Button value={Util.getMessage("Volver")} onClick={() => cancelCampaing()}/>) : null}
                </div> 
            </>
        );
  }
}

export default RememberProm;