import React, { Component } from 'react';
import * as Util from './../../data/General';
import Button from '../html/Button';
import { connect } from 'react-redux';
import Notify from '../html/Notify';
import Campaign from './Campaign';

class ListCampaigns extends Component {

    state ={
        notify: "",
        costMenssage: 0
    }

    componentDidMount() {
        Util.getCurrency(this.isOKRates);
    }

    isOKRates = (data) => {
        let shopify =  Util.getAdminShop();
        if (shopify && shopify.countryCode && data[shopify.countryCode]){
            let dat = data[shopify.countryCode];
            if(dat.rate){
                let costMenssage = dat.mkt * dat.rate;
                console.log("costMenssage", costMenssage);
                this.setState({costMenssage: costMenssage});         }
        }
    }

    handleOk = () => {
        const { handleOk } = this.props;
        console.log("handleOk");
        if (handleOk) {
            handleOk();
        }
    }

    goToPedding = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/reviews"
        });
    }

    
    updateImage = (review) => {
        if (review && review.product && review.product.id){
            Util.updateImage(review.product.id);
        }
    }

    render() {
        const { campaigns, title, type, isShowPoints, titleHtml=false, isShowTitle=true, isPay, show, hide, isShowProduct, shop, filter, setFilterOrder, edit, remember } = this.props;
        const { notify, costMenssage } = this.state;
        return (
            <div>
                {isShowTitle && !isShowProduct && (
                    <div className='title'>
                        {titleHtml ? (
                            <div className='content-flex btton-left'>
                                <span dangerouslySetInnerHTML={{__html: titleHtml }} />
                                <Button value={Util.getMessage("show_all")} type="primary" onClick={() => this.goToPedding()}/>
                            </div>
                        ) : (
                            <span>{Util.getMessage(title)}</span>
                        )}
                    </div> 
                )}
                
                {campaigns && campaigns.length > 0 ? (
                    <div>
                        <div>
                            {!isShowProduct ? (
                                <div>
                                    <div className="content-review content-product-item cont-camp-head">
                                        <div className="content-img-prod">
                                            <div className="content-flex flex-center">{Util.getMessage("Name_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "name" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                        </div>
                                        <div className="content-flex flex-center">
                                            <div onClick={()=> setFilterOrder("notifiedAt")} className="content-flex flex-center">{Util.getMessage("date_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "notifiedAt" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                            <div onClick={()=> setFilterOrder("state")} className="cont-stars cont-state titlecont content-flex flex-center">{Util.getMessage("state_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "state" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                            <div className='cont-total'>{Util.getMessage("total_table")}</div>
                                            <div className='cont-total'>{"#"}</div>
                                            <div className='cont-total cont-total-esp'>{"$"}</div>
                                            <div className='cont-total'>{"ROI"}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {campaigns.map((item, key) => (
                                <div key={key}>
                                    <Campaign type={type} costMens={(item.parentId && item.parentId !== "") ? costMenssage*0.3 : costMenssage } remember={remember} isShowProduct={isShowProduct} planIA={(shop && shop.plan && shop.plan.planType !== "50" && shop.plan.planType !== "0" && shop.plan.planType !== "200") ? true : false} dispatch={this.props.dispatch} hide={hide} show={show} product={item} isPay={isPay} updateImage={this.updateImage} isShowPoints={isShowPoints} handleOk={() => this.handleOk()} openModal={this.openModal} edit={edit}/>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='sR'>{Util.getMessage("WITHOUT")} {Util.getMessage(title)}</div>
                )}

                {notify !== "" && (
                    <Notify text={notify} />
                )}
            </div> 
        );
  }
}

const mapStateToProps = (state) => ({
    menuSelected: state.menu.menuSelected,
    menuShow: state.menu.menuShow,
    isMobile: state.system.isMobile,
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListCampaigns);
