import React, { Component } from 'react';
import * as Util from './../../data/General';

class Tabs extends Component {

    componentDidMount() {
        const { inicial } = this.props;
        if (inicial){
            this.setState({tabSelected: inicial});
        }        
    }

    state = {
        tabSelected: 0,
        subtype: 0
    };

    handleClick = (tab) => {
        const { handleClick } = this.props;
        this.setState({tabSelected: tab});
        if(handleClick){
            handleClick(tab);
        }
    }

    handleClickSubType = (tab) => {
        const { handleClickST } = this.props;
        this.setState({subtype: tab});
        if(handleClickST){
            handleClickST(parseInt(tab));
        }
    }

    render() {
        const { tabSelected, subtype } = this.state;
        const { tabs, className="", subTabs=null, listSubTabs=null } = this.props;

        return (
            <div className={className + ' content-tabs'}>
                <ul>
                    {tabs && tabs.map((item, key) => (
                        <>
                            <li 
                                key={key} className={tabSelected === key ? 'active' : ''} 
                                onClick={() => this.handleClick(key)}
                            >
                                {item}
                            {(subTabs && tabSelected === subTabs && key === subTabs)? (
                                <select value={subtype} onChange={(e) => this.handleClickSubType(e.target.value)}>
                                    {listSubTabs && listSubTabs.map((item2, key2) => (
                                        <option key={key2} value={key2}>{Util.getMessage(item2)}</option> 
                                    ))}
                                </select>
                            ) : null}
                            </li>
                        </>
                    ))}
                    
                    <li className='finish no-mobile'></li>
                </ul>
            </div>   
        );
  }
}

export default Tabs;