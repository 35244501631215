import React, { useEffect, useState } from 'react';

function StarListPoint(props) {
 const { star, count , total} = props;

 
  useEffect(() => {
    
  });

  return (
        <>
            <div className="ng-star-content">
                <div className="ng-label"> {star} <span className='ng-star'>★</span></div>
                <div className="c-ratingBreakdownGraph-row-bar">
                    <div className="ng-bar-background"></div>
                    <div className="ng-row-bar-foreground" style={{width: (count/total*100)+"%"}}></div>
                </div>
                <div className="ng-ratingBreakdownGraph">{count}</div>
            </div>
        </>
    );
}
export default StarListPoint;