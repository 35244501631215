import React, { Component } from 'react';

export const AUTH = { 
    domain:  'dev-w0yx0x16.auth0.com',
    client_id: 'N4sMCVvt4UtvbeDpD3CjT1j9xpnNGodG',
    response_type: 'token id_token',
    audience: "NicoIdentifier",
    socpe: 'openid profile email read:customers create:customers update:customers'
};

class Config extends Component {
}

export default Config;