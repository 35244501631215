import React, { Component } from 'react';

import * as Util from '../../data/General';

class SelectInput extends Component {

    state ={
        isCheck: false,
        text: "",
        timeOut: null
    }

    componentDidMount() {
        
    }

    onclcik = () => {
        const { isCheck, text } = this.state;
        this.setState({isCheck: !isCheck});
    }

    onChange = (evt) => {
        let { timeOut } = this.state;
        let value = evt.target.value;
      clearTimeout(timeOut);
      timeOut = setTimeout(function(){
        this.searchFinish();
      }.bind(this), 800);
      this.setState({timeOut: timeOut, text: value});
    }

    searchFinish = () => {
        const { onChange, name } = this.props;
        const { text } = this.state;
        if ( onChange ) {
            onChange(text, name);
        }
    }

    render() {
        const { isCheck } = this.state;
        const { titleHover='Startooltip', img=false, state = false } = this.props;
        return (
            <div className="content-flex select-filter" title={Util.getMessage(titleHover)}>
                {img && (
                    <img src={isCheck ? 'img/'+img+'_on.png' : 'img/'+img+'.png'} alt="star" width="24px" height="24px" onClick={() => this.onclcik()}/>
                )}
                {isCheck && (
                    <input type="search" onChange={e => this.onChange(e)}  className="form-control" placeholder="Search..."/>
                )}
            </div>     
        );
  }
}

export default SelectInput;