import React, { Component } from 'react';

import * as Util from './../../data/General';

class SelectStar extends Component {

    state ={
        start: 0
    }

    componentDidMount() {
        const { start } = this.props;
        if(start){
            this.setState({start: start});
        }
    }

    onclcik = (start) => {
        this.setState({start: start});
        const { onChange } = this.props;
        if ( onChange ) {
            onChange(start);
        }
    }

    render() {
        const { start } = this.state;
        const { isText=true, titleHover='Startooltip' } = this.props;
        return (
            <div className="content-flex points select-points" title={Util.getMessage(titleHover)}>
                {isText && (<span>{start === 0 ? Util.getMessage('StarFilter') : Util.getMessage(start+'Star')}</span>)}
                <div onClick={() => this.onclcik(1)} className={start > 0 ? "active" : ""}></div>
                <div onClick={() => this.onclcik(2)} className={start > 1 ? "active" : ""}></div>
                <div onClick={() => this.onclcik(3)} className={start > 2 ? "active" : ""}></div>
                <div onClick={() => this.onclcik(4)} className={start > 3 ? "active" : ""}></div>
                <div onClick={() => this.onclcik(5)} className={start > 4 ? "active" : ""}></div>
                { start !== 0 && isText && (
                    <i className="icofont-close-squared-alt" onClick={() => this.onclcik(0)}></i>
                )}
                {!isText && (
                    <span>({start}) </span>
                )}
            </div>     
        );
  }
}

export default SelectStar;