import React from 'react';
import { Switch } from 'react-router-dom';

import DefaultLayoutUnic from './components/layout/DefaultLayoutUnic';
import Login from './pages/Login';

const AppRoutes = () => 

    <Switch>
        {/*<DefaultLayout exact path="/home" component={Dashboard} />
        <DefaultLayout exact path="/reviews" component={Reviews} />
        <DefaultLayout exact path="/tc" component={TC} />
        <ExternalLayout exact path="/" component={Login} />/*/}
        <DefaultLayoutUnic path="*" component={Login} />
    </Switch>;

export default AppRoutes;