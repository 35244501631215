import React, { Component } from 'react';
import { connect } from 'react-redux';
import WhatsApp from './WhatsApp';
import InputHtml from './InputHtml';
import Button from './Button';
import Notify from './Notify';
import mixpanel from 'mixpanel-browser';
import * as Constants from './../../store/index';

import axios from "axios";

import * as Util from './../../data/General';
import TextAreaHtml from './TextAreaHtml';
import UploadImages from './UploadImages';
import Tabs from './Tabs';
import CardTemplate from './CardTemplate';

class CreateWhatsApp extends Component {


    state = {
      error: null,
      wa: null,
      notify: "",
      isShowUrl: true,
      isShopAdmin: false,
      subtype: 0,
      loading: false,
      tabs: [
        Util.getMessage("temp_watsapp_normal"),Util.getMessage("temp_watsapp_carousel")
      ],
      tab:-1,
      
    };

    
    componentDidMount() {
      let shopJson = Util.getAdminShop();
      let isShopAdmin =  false;

      let wa = {
        "sid": "",
        "mediaurl": "",
        "mediatext": "",
        "footer": Util.getMessage("wht_stop"),
        "body": "",
        "url": "",
        "buttontext": "",
        "websiteurl": "",
        "name": "",
        "templatename": "",
        "marketingCredential": {
          "twilioUsername": "",
          "twilioPassword": "",
          "messagingService": ""
        }, 
        isLoad: false,
        cards: null
      }
      if(shopJson){
          this.setState({shop: shopJson});
          mixpanel.init(window.mixpanelKey); 
          mixpanel.track('CREATE TEMPLATE WHATSAPP', {
              'shopDomain': shopJson.domain,
              'shopId': shopJson.id,
          });

          if(shopJson.id === Util.SHOP_ADMIN_ID){
            isShopAdmin = true;
          }
      }
      let { setUrl=null, editTemplate, waIA } = this.props;
      if(setUrl){
        wa.websiteurl = setUrl;
        this.setState({wa: wa, isShowUrl: false});
      }
      if(waIA){
        wa.body = waIA.body;
        wa.mediaurl = waIA.mediaurl;
        wa.name = waIA.name;
      }

      if(editTemplate){
        let cardsEdit = null
        let tab= 0;
        editTemplate.sid = "";
        if(editTemplate.url === ""){
          editTemplate.url = Util.getShopDomain();
        }

        if(editTemplate.cards && editTemplate.cards !== ""){
          cardsEdit = editTemplate.cards;
          tab = 1;
        }
        this.setState({isShopAdmin: isShopAdmin, wa: editTemplate, cards: cardsEdit, tab: tab});
      }else{
        this.setState({isShopAdmin: isShopAdmin, wa: wa, tab: 0});
      }
      
    }

    onBlurCampaing = (value, name) => {
      const { wa } = this.state;
      if(name === "websiteurl" && value.startsWith('http')){
        this.isError("error_whastsapp_6")
      }
      wa[name] = value; 
      this.setState({wa: wa});
    }

    isError = (errorNew) => {
      const { error } = this.state;
      if(error !== errorNew){
        this.setState({error: errorNew});
      }
    }
    newCampaigns = (example=false) =>{
        const { wa, subtype } = this.state;
        const { typeTemplate } = this.props;
        this.setState({isLoad: true});
        
        let url = window.url_server_2+"setcontent/?shop="+Util.getShopDomain();

        wa["accessTokenStore"] = Constants.getAccessTokenStore();
        wa["type"]= typeTemplate;
        if(example){
          wa["type"] = Util.EXAMPLE_TAB;
          wa["subtype"] = subtype;
          wa["url"] = "";
          wa["websiteurl"] = "";
        }
        axios.post(url, wa, {}).then(response => response.data,error => {
            return error;
          }).then((data) => {
            if (data && data.code === "200"){
              //let contentSid = data.data;
              //console.log(contentSid);
              const { oncreate } = this.props;
              this.setState({isLoad: false});
              if(oncreate){
                oncreate();
              }
            }else{
              alert("Ocurrio un error al crear la plantilla, comuniquese con Revie!");
            }
        });
    
    }

    generateIA = (value) => {
      //let { waIA } = this.props;
      const { wa } = this.state;
      wa["body"] = "";
      console.log("generateIA");
      /*if(waIA){
        this.setState({wa: wa,loading: true});
        Util.getBodyWhatsAppPHP(waIA.type, waIA.extra, this.isOKGenerate, this.isErrorGenerate);
      }else{*/
        //let shopJson = Util.getAdminShop();
        this.setState({wa: wa,loading: true});
        value = value.replace(/(\r\n|\n|\r)/gm, "")
        let extra = Util.getMessage("generic.query") + value;
        Util.getBodyWhatsAppPHP("generic", extra, this.isOKGenerate, this.isErrorGenerate);
      //}
    }

    isOKGenerate = (data, type, extra) => {
      console.log("data", data);
      const { wa } = this.state;
      if(data && data.data){
        wa["body"] = data.data;
        this.setState({wa: wa, loading:false});
      }
    }
  
    isErrorGenerate = (data) => {
      console.log("data", data);
    }

    addCards = (cards) => {

      cards[cards.length] = {
        "title":"",
        "body":"",
        "media":"",
        "actions":[
          {"type":"URL","title":"","url":""}
        ]
      };
      return cards;
    }

    handleClickTab= (data) => {
      const { wa, cards } = this.state;
      if(data === 1){
        wa.cards = [];
        wa.cards = this.addCards(wa.cards);
        wa.cards = this.addCards(wa.cards);
        wa.cards = this.addCards(wa.cards);
      }else{
        wa.cards = null;
      }
      this.setState({tab: data, wa: wa});
    }

    deleteCard= () => {
      const { wa, cards } = this.state;
      if(wa.cards.length > 2){
        wa.cards = wa.cards.slice(0, -1);
        this.setState({wa: wa});
      }
    }

    addCard= () => {
      const { wa, cards } = this.state;
      if(wa.cards.length < 6){
        wa.cards = this.addCards(wa.cards);
        this.setState({wa: wa});
      }
    }


    onBlurCampaingCrads = (item, key) => {
      const { wa } = this.state;
      //console.log(item, key);
      console.log(wa);
      wa.cards[key] = item;
      console.log(wa);
      this.setState({wa: wa});
    }

    validateCards = () => {
      const { wa } = this.state;
      let error = false;
      for (let index = 0; index < wa.cards.length; index++) {
        const element = wa.cards[index];
        if(!element.title || element.title === "" || !element.body || element.body === "" || !element.media || element.media === "" || !element.actions[0].url || element.actions[0].url === ""){
          error = true;
        }
      }
      return error;
    }

    render() {

      const { wa, notify, isLoad, error, isShowUrl, isShopAdmin, subtype, loading, tabs, tab } = this.state;
      if (!wa) return null;
      return (
        <div className={"content-reviews crate-wa tabselect-"+tab}>
          {tab !== -1 ? <Tabs tabs={tabs} handleClick={this.handleClickTab} inicial={tab}/> : null}
          <div className='content-flex space-between'>
            
            <div className='content-config'>
              <div className='text'>
                <h2>{Util.getMessage("form_1")}</h2>
                <InputHtml className="" placeholder="30OFF discount" edit={true} isOnlyTL={true} name="name" value={wa.name} onBlur={this.onBlurCampaing}/>
              </div>
                <div className='text' style={{"position": "relative"}}>
                    <h2>{Util.getMessage("form_2")}</h2>
                    {loading ? (
                      <div className="presentacion" >
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      </div>
                      ) : (
                        <TextAreaHtml addButton={true} className="" loading={loading} edit={true} name="body" value={wa.body} onBlur={this.onBlurCampaing} generateIA={this.generateIA}
                      smallError={Util.getMessage("create_whatsApp_help_body")}/>
                      )
                    }
                    
                  </div>
                {tab === 0 ? (
                  <>
                    <div className='text'>
                      <h2>{Util.getMessage("form_3_bis")}</h2>
                      <div style={{display: "none"}}><InputHtml className="" disabled={wa.mediatext !== ""} placeholder="https://" name="mediaurl" value={wa.mediaurl} onBlur={this.onBlurCampaing} 
                        smallError={Util.getMessage("create_whatsApp_help_mediaurl")}/></div>

                      <UploadImages disabled={wa.mediatext !== ""} formatcontrol={"PNG o JPG"} onBlur={this.onBlurCampaing} name="mediaurl" value={wa.mediaurl} />  
                    </div>
                    <div className='text'>
                      <h2>{Util.getMessage("form_4")}</h2>
                      <InputHtml className="" disabled={wa.mediaurl !== ""} placeholder="" name="mediatext" value={wa.mediatext} onBlur={this.onBlurCampaing} 
                        smallError={Util.getMessage("create_whatsApp_help_mediatext")}/>
                    </div>
                    <div className='text'>
                      <h2>{Util.getMessage("form_5")}</h2>
                      <InputHtml className="" placeholder="Type button text" name="buttontext" value={wa.buttontext} onBlur={this.onBlurCampaing}/>
                    </div>
                    {isShowUrl ? (
                      <div className='text'>
                        <h2>{Util.getMessage("form_6")}</h2>
                        <InputHtml className="" placeholder="https://" name="websiteurl" value={wa.websiteurl} onBlur={this.onBlurCampaing} smallError={Util.getMessage("create_whatsApp_help_websiteurl")}/>
                      </div>
                    ) : null}
                  </>) : (
                    <>
                      <hr style={{margin: "2rem 0rem 1rem 0rem",border: "1px solid #ccc"}}/>
                      <div className='text'>
                          <h2 style={{fontSize: "1.2rem",marginBottom: "1rem"}}>{Util.getMessage("title_cards")}</h2>
                        </div>
                      <div className='create-wa-cards'>
                        {wa.cards && wa.cards.map((item, key) => (
                          <CardTemplate index={key} card={item} onBlurCampaing={this.onBlurCampaingCrads} />
                        ))}
                      </div>
                      <div className='text button_campaign'>
                        <Button value={Util.getMessage("delete_card")}  loader={isLoad} onClick={() => this.deleteCard()}/>
                        <Button value={Util.getMessage("add_card")}  loader={isLoad} onClick={() => this.addCard()}/>
                      </div>
                      <hr style={{margin: "2rem 0rem 1rem 0rem",border: "1px solid #ccc"}}/>
                    </>
                    )}
                {error && <div className="error" >{Util.getMessage(error)}</div>}
                  
                <div className='text button_campaign'>
                  <Button value={Util.getMessage("btn_create")} disabled={error !== null || (tab === 0 ? (wa.name === "" || wa.body === "" || wa.buttontext === "" || wa.websiteurl === "") : (wa.name === "" || wa.body === "" || this.validateCards()))} loader={isLoad} type="primary" onClick={() => this.newCampaigns()}/>

                  {isShopAdmin ? (
                    <>
                      <select value={subtype} onChange={(e) => this.setState({subtype: e.target.value})}>
                        {Util.LISTSUBTYPE && Util.LISTSUBTYPE.map((item2, key2) => (
                            <option key={key2} value={item2}>{Util.getMessage(item2)}</option> 
                        ))}
                      </select>
                      <Button value={"Crear plantilla de ejemplo"} disabled={error !== null || wa.name === "" || wa.body === "" || wa.buttontext === "" } loader={isLoad} type="primary" onClick={() => this.newCampaigns(true)}/>
                    </>
                    
                  ): null}
                </div>
                
              </div>
            
            <div className='cont-wp'>
              <WhatsApp message={wa} isError={this.isError}/>
            </div>
          </div>
          {notify !== "" && (
              <Notify text={notify} />
          )}
        </div>
        );
        }
      }
    
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWhatsApp);
