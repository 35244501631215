export default[
{
    "title": "Dashboard",
    "url": "/home",
    icon:"icofont-home"
},{
    "title": "Tiendas",
    "url": "/shops",
    icon: "icofont-grocery"
},{
    "title": "Configuración",
    "url": "/config",
    icon: "icofont-settings"
}
];