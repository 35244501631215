import React, { useEffect, useState } from 'react';

import * as Util from './../../data/General';
import StarListPoint from './StarListPoint';

function StarList(props) {
 const { id, url= null} = props;

 const [stars, setStars] = useState({});
 const [total, setTotal] = useState(0);

 const handleOkStars = (data) => {
    let dataStar= {};
    let total = 0;
    {data.map((tag, key) => {
        dataStar[tag["key"]]=tag["count"];
        total += tag["count"];
    })}
    setStars(dataStar);
    setTotal(total);
 }

 const handleErrorState = (data) => {
    console.log(data);
 }

  useEffect(() => {
    if(url){
        Util.getStarstatistics(url, handleOkStars, handleErrorState, false);
    }else{
        let filterNew = {};
        filterNew.productId = id;
        filterNew.state = "APPROVED, REJECTED, PENDING_APPROVAL";
        console.log("useEffect");
        Util.getStarstatistics(filterNew, handleOkStars, handleErrorState);
    }
  },[]);

  return (
        <div className='star-list'>
            <StarListPoint star="5" count={stars["5"] ? stars["5"] : 0} total={total} />
            <StarListPoint star="4" count={stars["4"] ? stars["4"] : 0} total={total} />
            <StarListPoint star="3" count={stars["3"] ? stars["3"] : 0} total={total} />
            <StarListPoint star="2" count={stars["2"] ? stars["2"] : 0} total={total} />
            <StarListPoint star="1" count={stars["1"] ? stars["1"] : 0} total={total} />
        </div>
    );
}
export default StarList;