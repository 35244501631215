import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardCards from '../components/widget/DashboardCards';
import DashboardCardsBottom from '../components/widget/DashboardCardsBottom';
import ListReviews from '../components/widget/ListReviews';
import Table from '../components/widget/Table';
import  * as Util from './../data/General';
import Filter from '../components/widget/Filter';
import mixpanel from 'mixpanel-browser';

import * as Constants from './../store/index';
import Button from '../components/html/Button';
import Notify from '../components/html/Notify';

class Dashboard extends Component {
  state = {
    header:[
      {label:Util.getMessage("home_table_1"), id:"product", subId:"name", className: 'ellipsis w8R'},
      {label:Util.getMessage("home_table_2"), id:"orderName", className: 'ellipsis w8R'},
      {label:Util.getMessage("home_table_3"), id:"createdAt", type:"date"},
      {label:Util.getMessage("home_table_4"), id:"state", type:"status"}
    ],
    reviews: null,
    reviewList: null,
    approved: 0,
    pendingApproval: 0,
    total: 0,
    rejected:0,
    send: 0,
    news: 0,
    error: 0,
    average: 0,
    receive: 0,
    startsList:null,
    filterState:null,
    shop: null,
    isShowMS: false,
    timeout: null,
    dataInstall: {
      sync: false,
      total: 0,
      reviews: 0,
      count: 0
    },
    isInstallationPossible: true,
    notify: "",
    utm: null,
    isPay: false,
  }

  componentDidMount() {
    let filter = {...Util.filterInic};
    filter.size = 10;
    filter.state = '';
    this.setState({filterState: filter});

    let shopJson =  Util.getAdminShop();
    if(shopJson){
        Util.setLanguage(shopJson);
        let isPay = (shopJson.plan && shopJson.plan.planType !== "50" && shopJson.plan.planType !== "0");
        this.setState({shop: shopJson, isPay: isPay});
        mixpanel.init(window.mixpanelKey); 
        mixpanel.track('AD Dashboard', {
            'shopDomain': shopJson.domain,
            'shopId': shopJson.id,
        });
    }
    Util.getUtmStatistics(this.handleOkUTM, this.handleErrorUTM);
    if (window.location && window.location.search.startsWith('?strike_success=true')){
      this.setState({notify: Util.getMessage("success_plan_tiendaNube")});
      
      setTimeout(function(){
        Util.getInfoShop(this.handleOkShopPlan, this.handleOkShopError);
      }.bind(this), 25000);
      
    }

    setTimeout(function(){
      this.updateDashboard();
    }.bind(this), 200);

  }

  componentWillUnmount() {
    if (this.state.timeout) clearTimeout(this.state.timeout);
  }
  updateDashboard = () => {
    let { filterState } =  this.state;
    this.setFilter(filterState);
    let timeout = setTimeout(function(){
      this.updateDashboard();
    }.bind(this), 60000);
    this.setState({timeout: timeout});
  
  }


  handleOkShopPlan = (data) => {
    console.log(data);
    if (data && data.id){
        this.setState({shop: data, isInstalled: false, isload: false});
        localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        window.location.href = '/';
        this.props.dispatch({
          type: 'CHANGE_MENU',
          new_option: '/plan'
        });
    }
  }

  handleOkShopError = (data) => {
    console.log(data);
  }

  handleOkUTM = (data) => {
    if (data && data.totalPrice > 0){
      this.setState({utm: data});
    }
  }

  handleErrorUTM = (data) => {
    console.log(data);
  }

  setFilter = (filter) =>{
    this.setState({filterState: filter});
    if(filter.fromUpdateDate){
      filter.fromDate = filter.fromUpdateDate;
      filter.toDate = filter.toUpdateDate;
    }
    Util.getReviews(filter, this.handleOkTable, this.handleError);
    Util.getStatestatistics(filter, this.handleOkState, this.handleErrorState);
    let filterNew = filter;
    filterNew.state = 'PENDING_APPROVAL,APPROVED,REJECTED';
    Util.getStarstatistics(filterNew, this.handleOkStars, this.handleErrorState);

    this.pending(filter);
  }

  pending = (filter) => {
    const { filterState } = this.state;
    let filterNew = filter ? filter : filterState;
    filterNew.size = 3;
    filterNew.state = 'PENDING_APPROVAL';
    Util.getReviews(filterNew, this.handleOk, this.handleError);
  }

  handleOk = (data) => {
    
    this.setState({reviewList: data});
  }

  handleOkTable = (data) => {
    this.setState({reviews: data});
  }

  handleOkStars = (data) => {
    if(data && data.length > 0){
      let startsList = [];
      startsList[0] =0;
      startsList[1] =0;
      startsList[2] =0;
      let count = 0;
      let average = 0;
      const { isInstallationPossible } = this.state;
      if (isInstallationPossible){
        this.setState({isInstallationPossible: false});
      }   
      data.map((item, key) => {
        if(item.key === "5"){
          startsList[0] = item.count;
        }else if(item.key === "4"){
          startsList[1] = item.count;
        }else if(item.key === "3"){
          startsList[2] = startsList[2] + item.count;
        }else if(item.key === "2"){
          startsList[2] = startsList[2] + item.count;
        }else if(item.key === "1"){
          startsList[2] = startsList[2] + item.count;
        }
        count+= item.count;
        average+= (item.count* parseInt(item.key));
      });
      average = (average / count).toFixed(2);
      this.setState({startsList: startsList, average: average});
    }
  }

  handleError = (data) => {
    //this.setState({reviews: data});
  }

  handleOkState = (data) => {
    let approved=0;
    let pendingApproval=0;
    let total=0;
    let rejected=0;
    let send=0;
    let news = 0;
    let error = 0;
    let ignored = 0;
    let receive = 0;
    let all = 0;
    if(data && data.length > 0){
      data.map((item, key) => {
        if(item.key === "APPROVED"){
          approved = item.count;
        }else if(item.key === "PENDING_APPROVAL"){
          pendingApproval = item.count;
        }else if(item.key === "REJECTED"){
          rejected = item.count;
        }else if(item.key === "SENT"){
          send = item.count;
        }else if(item.key === "NEW"){
          news = item.count;
        }else if(item.key === "EXPIRED"){
          error = item.count;
        }else if(item.key === "IGNORED"){
          ignored = item.count;
        }
      });
      total = pendingApproval+approved+rejected ;
      all =pendingApproval+approved+rejected +send+news+error+ignored;
      receive = send+pendingApproval+approved+rejected+error;
      console.log(total);
      this.setState({rejected: rejected, pendingApproval: pendingApproval, approved: approved, total: total, send: send, news: news, error: error, receive:receive});
      if(pendingApproval > 0){
        document.querySelector('title').textContent = Util.getMessage("title_PENDING_APPROVAL").replace("{{1}}", pendingApproval);
      }else{
        document.querySelector('title').textContent = "Revie";
      }
    }

    let { isInstallationPossible, dataInstall, shop } =  this.state;
    const { login } = this.props;
    let isShowMS = false;
    
    if(isInstallationPossible && login.step === 4 && !login.install){ // 
      isShowMS = true;
      if(all > 0 ){
        dataInstall.sync= true;
        dataInstall.total= all;
        dataInstall.count = dataInstall.count + 1;
      }

      if(send > 0){
        dataInstall.reviews= send;
        dataInstall.count = dataInstall.count + 1;
      }

      if(approved > 0 || dataInstall.count > 6 || (shop && shop.daysOrders === "")){
        login.install = true;
        login.shopId = shop ? shop.id : "";
        localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
      }

      

    }
    this.setState({isShowMS: isShowMS, dataInstall: dataInstall});
  }

  handleErrorState = (data) => {
    //this.setState({reviews: data});
  }

  goToPedding = () => {
    this.props.dispatch({
        type: 'CHANGE_MENU',
        new_option: "/plan"
    });
  }

  render() {
    const { header, shop, utm, reviews, approved, total, reviewList, date, startsList, isShowMS, dataInstall, average, pendingApproval, receive, notify, isPay, filterState } = this.state;
    let percet = 0;
    if(shop && shop.plan){
      if(shop.plan.planType !== "0"){
        percet = (shop.plan.currentReview * 100) / shop.plan.totalReview;
      }else{
        percet = -1;
      }
    }
    
    return (
        <div className="home">
          <Filter setFilter={this.setFilter} filter={filterState} />
          <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
            <DashboardCards title={Util.getMessage("home_total")} date={date} count={total} percentage={(total) ? (Math.trunc( (total * 100) / receive)) : "0"}/>
            <DashboardCards title={Util.getMessage("home_approved")} date={date} count={approved} percentage={total && approved ? Math.trunc((approved / total) * 100) : "0"}/>
            <DashboardCards title={Util.getMessage("home_global")} textTotal={Util.getMessage("home_starts")} start={true} titleTotal="" date={date} count={average} addPercentage="" percentage={average}/>
          </div>
          {startsList && startsList.length > 0 && utm === null ? (
            <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
              <DashboardCardsBottom title={Util.getMessage("home_5_start")} date={date} count={startsList[0]}/>
              <DashboardCardsBottom title={Util.getMessage("home_4_start")} date={date} count={startsList[1]}/>
              <DashboardCardsBottom title={Util.getMessage("home_3_start")} date={date} count={startsList[2]}/>
            </div>
          ) : (startsList && startsList.length > 0 && utm !== null) && (
            <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
              <DashboardCardsBottom title={Util.getMessage("home_5_start_add")} date={date} count={startsList[0] + startsList[1]}/>
              <DashboardCardsBottom title={Util.getMessage("home_3_start")} date={date} count={startsList[2]}/>
              <DashboardCardsBottom title={Util.getMessage("home_total_sales")} date={date} amount={utm.totalPrice} mediumCount={utm.mediumCount}/>
            </div>
          )}
          
          <div className='spacing'></div>
          <div className='content-flex space-between-start content-flex-two'>
              <div>
                {shop && shop.plan && ((shop.plan.planType === "0" || shop.plan.planType === "50" || shop.plan.planType === "FREE") || (percet >= 0 && percet > 75)) && (
                  <div  className='content-table'>
                    {shop.plan.planType === "0" ? 
                      null : 
                      <div className='title'>{Util.getMessage("home_plan_1")} 
                        {shop.plan.planType === "FREE" ? " " + Util.getMessage("option_free_10") : " " + shop.plan.planType}
                      </div> 
                    }
                    <div className='card-home card-home-bottom home'>
                      {percet >= 0 && percet > 75 && (
                        <div className={`plan-actual ${percet > 95 ? 'maxReviews' : (percet > 75 ? 'medium' : '')}`}>
                            <div id="progressbar">
                                <div style={{width: percet.toFixed(1)+ "%"}}>{(percet).toFixed(1)+ "%"}&nbsp;&nbsp;</div>
                            </div>
                        </div>
                      )}
                      {shop.plan.planType === "0" || shop.plan.planType === "50" || shop.plan.planType === "FREE" ? (
                        <div className='actual proximo error'>
                          {shop.plan.planType === "0" ? (
                            <>{Util.getMessage("plan_0_error")}</>
                          ) : (
                            <>{Util.getMessage("plan_50_error")}</>
                          )}
                          <Button value={Util.getMessage("update_plan")} type="primary" onClick={() => this.goToPedding()}/>
                        </div>
                      ) : percet > 95 ? (
                        <div className='actual proximo error'>
                          {Util.getMessage("plan_200_error")}
                          <Button value={Util.getMessage("update_plan")} type="primary" onClick={() => this.goToPedding()}/>
                        </div>
                      ): percet > 75 ? (
                        <div className='actual proximo error'>
                          {Util.getMessage("plan_200_error_1")}
                          <Button value={Util.getMessage("update_plan")} type="primary" onClick={() => this.goToPedding()}/>
                        </div>
                      ) : null} {/*shop && (shop.plan.currentReview === shop.plan.totalReview || (percet > 25 && (shop.plan.planType === "50" || shop.plan.planType === "0"))) && (
                        <div className='actual proximo error'>
                          {percet > 35 ? (
                            <>{Util.getMessage("plan_finish")}</>
                          ) : (percet > 25 && (
                            <>{Util.getMessage("plan_finish_inc")}</>
                          ))}
                          <Button value={Util.getMessage("update_plan")} type="primary" onClick={() => this.goToPedding()}/></div>
                          )*/}
                    </div>
                  </div>
                )}  
                <Table header={header} body={reviews} title={Util.getMessage("home_news_reviews")} isShowMS={isShowMS} dataInstall={dataInstall} shop={shop}/>
              </div>
            {reviews && (
              <ListReviews reviews={reviewList} isPay={isPay} title={Util.getMessage("home_reviews_pendings")} titleHtml={pendingApproval + " " +Util.getMessage("home_reviews_new")} handleOk={this.pending}/>
            )}
          </div>
          
          <div className='spacing'></div>

          {notify !== "" && (
              <Notify text={notify} />
          )}
        </div>
    )
  }
  
}
const mapStateToProps = (state) => ({
  lastWeek: state.lastWeek, 
  today: state.today,
  user: state.user,
  filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
