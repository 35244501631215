import React, { Component } from 'react';
import { connect } from 'react-redux';


class Content extends Component {
  render() {
    return (
      <div className="Content" id="content">
        {this.props.children}

        {/*<footer id="footer" className="footer t-a-c mt-3  ">
          <div className="text-center text-md-center">
            <div className="text-center">
              <a href="/tc" className="mr-2">
              Términos y Condiciones </a>
            © 2022 GOSHIPPING.MX
            </div>
          </div>
    </footer>*/}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  menuShow: state.menu.menuShow,
  menuSelected: state.menu.menuSelected,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);