import React, { Component } from 'react';

import * as Util from './../../data/General';


class InputHtml extends Component {

    state = { mError: "", isFocus: false, textFilter: "", timeOut: null, listSearch: null, productSelected: null };

    componentDidMount() {
        const { value } = this.props;
        this.onValid(value);
    }

    handleInputFocus = (e) => {
        this.setState({
            isFocus: true
        });
    }
    handleOnBlur = (e) => {

        const { update, name } = this.props;
        this.setState({
            isFocus: false
        });
        if (update){
            update(name);
        }
    }
    onChange = (evt, pattern) => {
        const { onBlur, name, preword, notWeekend, isOnlyTL=false } = this.props;
        let value = evt;
        if(evt.target){
            value = evt.target.value;
        }
        if(pattern){
            value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
        }
        if (preword){
            value = preword + value;
        }

        if(isOnlyTL){
            value = value.replace(/[^\w\s]/gi, '');
        }
        this.onValid(value);
        let isDayValid = true;
        if (notWeekend){
            let day = new Date(value).getUTCDay();
            if([6,0].includes(day)){
                this.setState({
                    mError: "Fines de semana no permitidos"
                });
                isDayValid = false;
            }
        }
        
        if (onBlur && isDayValid){
            onBlur(value, name);
        }
        
    }

    onChangeColor = (evt) => {
        const { onBlur, name } = this.props;
        let value = evt.target.value;
        value = value.replace(/[^0-9#AaBbCcDdEeFf]/g, '');
        if (onBlur){
            onBlur(value, name);
        }
        
    }
    onValid = (value) => {
        const { required, maxlength } = this.props;
        const { mError } = this.state;
        if (required && value === ""){
            this.setState({
                mError: "Este campo no puede estar vacio"
            });
        }else if (value && maxlength && value.length > maxlength){
            this.setState({
                mError: "Supera por "+(value.length - maxlength)+" caracteres, el máximo permitido de "+maxlength+" caracteres."
            });
        } else if (mError !== ""){
            this.setState({
                mError: ""
            });
        }
    }

    searchFinish = () =>{
        const { textFilter, timeOut } = this.state;
        clearTimeout(timeOut);
        Util.getProduct(`status:ACTIVE AND title:${textFilter}*`, this.filterOk,this.filterError);
      }
  
      searchProducts = (text) =>{
        let { timeOut, textFilter } = this.state;
        this.setState({valueSearch: text, productSelected: null});
        var withNoDigits = text.replace(/[0-9]/g, '');
        if(withNoDigits.length === 0){
            const { onBlur, name } = this.props;
            if (onBlur){
                //onBlur(text, name);
            }
            return false;
        }else{
            textFilter = text;
            clearTimeout(timeOut);
            timeOut = setTimeout(function(){
            this.searchFinish();
            }.bind(this), 800);
            this.setState({timeOut: timeOut, textFilter: textFilter});
            
            return false;  
        }
        
      }

      filterOk=(data)=>{
        console.log(data);
        if(data && data.length > 0){
            this.setState({listSearch: data});
        }else{
            this.setState({listSearch: null});
        }
      }
      filterError=(data)=>{
        console.log(data);
      }

      onChangeDiv=(item)=>{
        const { onBlurListProduct, name, onBlurListProductImage } = this.props;
        if (onBlurListProduct){
            onBlurListProduct(item.id, item.title, name);
        }else if (onBlurListProductImage){
            onBlurListProductImage(item.id, item.title, item.featuredImage.transformedSrc, name);
        }
        this.setState({listSearch: null, valueSearch: "", productSelected: item});
      }

      returnItem = (item, key) =>{
        return (
        <div key={key} onClick={() => this.onChangeDiv(item)}>
            {item.featuredImage && item.featuredImage.transformedSrc ? (
                <img src={`${item.featuredImage.transformedSrc}`} alt="show" />
            ) : null }
            {item.title+" ["+item.id+"]"}
        </div>
        );
      }
    
  
    render() {
        const { pre, type, readonly, small, disabled=false, placeholder, max, pattern, post, edit=true, value, className, min, onClick, placeholderEmpty, unit, defaultElement } = this.props;
        const {mError, listSearch, valueSearch, productSelected} = this.state;
        return (
            <React.Fragment>
                <div className={` ${(!edit) && 'readonly'} ${className && className} ${type === "color" ? 'content-input-color' : ''} `}>
                    {pre && (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{pre}</span>
                        </div>
                    )}
                    {edit ? (
                        <>
                            {type === "background" ? (
                                <>
                                    {defaultElement && defaultElement.length > 0 && (
                                        <>  {/*Object.keys(item)[0]*/}
                                            <div className="input-color-prepend">
                                                { defaultElement.map((item, key) => (
                                                    <div key={key} className="input-form box" 
                                                        style={{background: `linear-gradient(110deg, ${item.backgroundinic} 60%, ${item.backgroundfinish} 60%)`, padding: "5px",height: "30px", width: "30px", cursor: 'pointer'}} onClick={() => this.onChange(item, pattern)} 
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : type === "size" ? (
                                <div className='social-size' onClick={() => this.onChange(value)}>
                                    <div className={`social-size-in size-${value}`}></div>
                                </div>
                            ) : type === "mode" ? (
                                <>
                                    <div className='social-mode' onClick={() => this.onChange(value)}>
                                        <img src={`img/mode-${value}.png`} alt="show" />
                                    </div>
                                </>
                            ) : type === "font" ? (
                                <div className='social-font'>
                                    {defaultElement && defaultElement.length > 0 && (
                                        <>  {/*Object.keys(item)[0]*/}
                                            <div className="input-color-prepend">
                                                { defaultElement.map((item, key) => (
                                                    <div key={key} style={{fontFamily: item}} className={`fonts-soscial ${item === value ? 'selected' : ''}`} 
                                                         onClick={() => this.onChange(item)} 
                                                    >
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    <hr/>
                                </div>
                            ) : type === "listproduct" ? (
                                <div className='social-font'>
                                    <input  className={`input-form ${productSelected ? 'selected' : ''}`}  value={valueSearch} placeholder={placeholder} name="product" onChange={(e) => this.searchProducts(e.target.value)} />
                                    {productSelected ? (
                                        <div id="browsers" className='selected'>
                                            {this.returnItem(productSelected, "a")}
                                        </div>
                                    ) : null}
                                    {listSearch ? (
                                        <div id="browsers">
                                            {listSearch.map((item, key) => (<>{this.returnItem(item, key)}</>))}
                                        </div>
                                    ) : (null)}
                                </div>
                            ) : (
                                <>
                                    <input className="input-form" type={type} disabled={disabled}
                                    value={value} onChange={e => this.onChange(e, pattern)} 
                                    onBlur={this.handleOnBlur} onFocus={this.handleInputFocus} onClick={onClick} readOnly={readonly ? true : false} 
                                    placeholder={placeholder} 
                                    max={max ? max : ''}
                                    min={min ? min : ''}
                                    pattern={pattern ? pattern : ''}/>
                                    {type === "range" && (
                                        <output>{value}{unit}</output>
                                    )}

                                    {type === "color" && (
                                        <>
                                            <input className="input-form min" type="text"
                                                value={value.startsWith("#") ? value : ''} onChange={e => this.onChangeColor(e)} placeholder="#000000"
                                                onBlur={this.handleOnBlur} onFocus={this.handleInputFocus} onClick={onClick} readOnly={readonly ? true : false} 
                                            />
                                            {defaultElement && defaultElement.length > 0 && (
                                                <>
                                                    <div className="input-color-prepend">
                                                        { defaultElement.map((item, key) => (
                                                            <div key={key} className="input-form" 
                                                                style={{background: item, padding: "5px",height: "30px", width: "30px", cursor: 'pointer'}} onClick={() => this.onChange(item, pattern)} 
                                                            />
                                                        ))}
                                                    </div>
                                                    <hr/>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <span className="text">{(value && value !== "") ? value : placeholderEmpty}</span>
                    )}
                    {post && (
                        <div className="input-group-append">
                            <span className="input-group-text">{post}</span>
                        </div>
                    )}
                    {(mError !== "") && (<div className="error">{mError}</div>)}
                    {small && (
                        <small dangerouslySetInnerHTML={{__html: small }} />
                    )}
                </div>    
            </React.Fragment>     
        );
  }
}

export default InputHtml;