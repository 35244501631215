import React, { Component } from 'react';

import * as Util from '../../data/General';

class SelectFilter extends Component {

    state ={
        isCheck: false
    }

    componentDidMount() {
        
    }

    onclcik = () => {
        const { isCheck } = this.state;
        
        const { onChange, name } = this.props;
        if ( onChange ) {
            onChange(!isCheck, name);
        }
        this.setState({isCheck: !isCheck});
    }

    render() {
        const { isCheck } = this.state;
        const { titleHover='Startooltip', img=false, state = false } = this.props;
        return (
            <div className="content-flex select-filter" title={Util.getMessage(titleHover)}>
                {img && (
                    <img src={isCheck ? 'img/'+img+'_on.png' : 'img/'+img+'.png'} alt="star" width="24px" height="24px" onClick={() => this.onclcik()}/>
                )}
                {state && (
                    <div className={`sentiment-content ${state} ${isCheck ? 'ON' : ''} `} onClick={() => this.onclcik()}>{state.substring(0, 1)}</div>
                )}
            </div>     
        );
  }
}

export default SelectFilter;