import React, { useState, useEffect } from 'react';

import * as Util from '../data/General';
import mixpanel from 'mixpanel-browser';

function FrequentQuestions(props) {
  useEffect(() => {
    let shopJson =  Util.getAdminShop();
    if(shopJson){
      mixpanel.init(window.mixpanelKey); 
      mixpanel.track('Frequent questions', {
          'shopDomain': shopJson.domain,
          'shopId': shopJson.id,
      });
    }
  },[]);

  
  return (
    <div>
      <h1>{Util.getMessage("frequentquestions_title")}</h1>
      <div className='frequent-questions'>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/como-empiezo-a-recibir-reviews-por-whatsapp-1mssmum/">{Util.getMessage("frequentquestions_t1")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/desde-que-numero-de-telefono-se-solicitan-los-reviews-11niojp">{Util.getMessage("frequentquestions_t2")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/como-se-ve-el-mensaje-que-reciben-los-clientes-para-solicitar-el-review-15mb3n1">{Util.getMessage("frequentquestions_t3")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/puedo-seleccionar-cuales-reviews-mostrar-en-mi-tienda-1dnma5m/">{Util.getMessage("frequentquestions_t4")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/como-mostrar-los-reviews-en-mi-tienda-en-linea-kwbcn6">{Util.getMessage("frequentquestions_t5")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/como-puedo-importar-mis-resenas-de-otra-aplicacion-143vt07">{Util.getMessage("frequentquestions_t6")}</a>
        </div>
        <div>
          <a target='_blank' href="https://help.revie.ai/es/article/como-puedo-exportar-mis-resenas-1eln3rd">{Util.getMessage("frequentquestions_t7")}</a>
        </div>
      </div>
    </div>
);
}

export default FrequentQuestions;