import React, { Component } from 'react';

import  * as Util from './../../data/General';


class RadioHtml extends Component {
    state = {value: ""};

    componentDidMount() {
        const { value } = this.props;
        console.log(value);
        this.setState({value: value});
    }

    handleClick = (value) => {
        const { onChange, name } = this.props;
        
        if (onChange){
            onChange(value, name);
        }
        this.setState({value: value});
    }
  
    render() {
        const { edit=true, options, name, type="radio", recomend } = this.props;
        const { value } = this.state;
        console.log(value);
        return (
            <div>
                {edit ? (
                    <div>
                        {options && Object.keys(options).map(key =>
                            <React.Fragment>
                                <div className="form-check form-check-conteiner" key={key} onClick={() => this.handleClick(key)}>
                                    <input className="form-check-input" type={type} name={name} 
                                        id={options[key]} 
                                        value={key} checked={(value === key)}
                                    />
                                    <span className="form-check-span"></span>
                                    <label className="form-check-label" htmlFor={options[key]}>
                                        {Util.getMessage(options[key])}
                                    </label>
                                    {recomend && recomend.value === key && (
                                        <div className="recomend">{recomend.label}</div>
                                    )}  
                                </div>   
                            </React.Fragment>     
                        )}
                    </div> 
            ) : (
                <div>
                    <label className="mode-edit">{options[value]}</label>  
                </div>     
            )}  
            </div>
        );
  }
}

export default RadioHtml;