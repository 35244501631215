import React, { Component } from 'react';
import Button from './Button';
import * as Util from './../../data/General';



class TextAreaHtml extends Component {

    state = { mError: "", isFocus: false, inicAdd: 1, selStart: null, selEnd: null};

    componentDidMount() {
        const { value } = this.props;
        this.onValid(value);
    }

    handleInputFocus = (e) => {
        this.setState({
            isFocus: true
        });
    }
    handleOnBlur = (e) => {
        const { update, name } = this.props;

        this.setState({
            isFocus: false
        });

        if(update){
            update(name);
        }
    }
    onChange = (evt, pattern) => {
        const { onBlur, name, preword } = this.props;
        let value = evt.target.value;
        if(pattern){
            value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
        }
        if (preword){
            value = preword + value;
        }
        if (onBlur){
            onBlur(value, name);
        }
        this.onValid(value);
    }
    onValid = (value) => {
        const { required, maxlength } = this.props;
        const { mError } = this.state;
        if (required && value === ""){
            this.setState({
                mError: "Este campo no puede estar vacio"
            });
        }else if (value && maxlength && value.length > maxlength){
            this.setState({
                mError: "Supera por "+(value.length - maxlength)+" caracteres, el máximo permitido de "+maxlength+" caracteres."
            });
        } else if (mError !== ""){
            this.setState({
                mError: ""
            });
        }
    }

    addVar = (newValue, isSpecial=true) =>{
        const {  pattern, value, maxAdd } = this.props;
        const { inicAdd, selStart, selEnd } = this.state;
        let newString = value;
        //console.log(selStart);
        if(isSpecial){
            if(selStart !== selEnd){
                var position = selEnd;
                newString = [newString.slice(0, position), newValue, newString.slice(position)].join('');
                position = selStart;
                newString = [newString.slice(0, position), newValue, newString.slice(position)].join('');
                
            }else{
                var position = selStart;
                newString = [newString.slice(0, position), newValue + newValue, newString.slice(position)].join(''); 
            }
        }else{
            var position = selStart;
            newString = [newString.slice(0, position), newValue, newString.slice(position)].join(''); 
            if(inicAdd < maxAdd){
                this.setState({inicAdd: inicAdd+1});
            }
        }

        let evt={target:{value: newString}};
        this.onChange(evt, pattern);
        let elem = document.getElementById("textareavar");
        if (elem){
            elem.value = newString;
            elem.focus();
        }
    }
    
    isDisabledVar = () => {
        const { value } = this.props;
        const { inicAdd } = this.state;
        return value.includes("{{"+inicAdd+"}}");
    }

    onSelect = (event) => {
        const { addButton } = this.props;

        if(addButton){
            this.setState({selStart: event.target.selectionStart, selEnd: event.target.selectionEnd});
            //console.log(event.target.selectionStart, event.target.selectionEnd);
        }
    }

    generateIA = () => {
        const { generateIA, value } = this.props;
        if(generateIA){
            generateIA(value);
        }
    }
    
  
    render() {
        const { pre, readonly, small, addButton, loading, max, pattern, post, edit, value, className, min, onClick } = this.props;
        const {mError, inicAdd} = this.state;
        return (
            <React.Fragment>
                <div className={` ${(!edit) && 'readonly'} ${className && className}`}>
                    {pre && (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{pre}</span>
                        </div>
                    )}
                    {addButton &&(
                        <div className='wa-add bt-img-14'>
                            <Button value={"B"} onClick={() => this.addVar("*")}/>
                            <Button value={"I"} onClick={() => this.addVar("_")}/>
                            <Button value={"S"} onClick={() => this.addVar("~")}/>
                            <Button value={Util.getMessage("btn_add_var")} disabled={this.isDisabledVar()} type="primary" onClick={() => this.addVar(`{{${inicAdd}}}`, false)}/>
                            <Button value={Util.getMessage("btn_add_var_IA")}  image="/img/ia/chatgp2.png" disabled={loading || value.length < 30} type="primary" onClick={() => this.generateIA()}/>
                        </div>
                    )}
                    {edit ? (
                        <textarea id="textareavar" onSelect={e => this.onSelect(e)} className="input-form" onChange={e => this.onChange(e, pattern)} 
                        onBlur={this.handleOnBlur} onFocus={this.handleInputFocus} onClick={onClick} readOnly={readonly ? true : false} 
                        max={max ? max : ''}
                        min={min ? min : ''} defaultValue={value}
                        pattern={pattern ? pattern : ''}></textarea>
                    ) : (
                        <span className="text">{value}</span>
                    )}
                    {post && (
                        <div className="input-group-append">
                            <span className="input-group-text">{post}</span>
                        </div>
                    )}
                    {(mError !== "") && (<div className="error">Error: {mError}</div>)}
                    {small && (
                        <small dangerouslySetInnerHTML={{__html: small }} />
                    )}
                </div>    
            </React.Fragment>     
        );
  }
}

export default TextAreaHtml;