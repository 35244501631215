import React, { useState, useEffect } from 'react';
import  * as Util from '../../data/General';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';

import 'react-day-picker/dist/style.css';

function Filter(props) {
    let { filter, setFilter, fromText="fromUpdateDate", toText="toUpdateDate", className="", isIntervalShow=true } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(360);
    const [endDate, setEndDate] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());
    const days = {
        0:{day: 0, title: Util.getMessage("Today")},
        1: {day: 1, title: Util.getMessage("Yesterday")},
        7: {day: 7, title: Util.getMessage("fl_7")},
        30: {day: 30, title: Util.getMessage("fl_30")},
        90: {day: 90, title: Util.getMessage("fl_90")},
        360: {day: 360, title: Util.getMessage("fl_360")},
    };
    const [interval, setInterval] = useState("day");
    const intervalList = {
        "hour": {title: Util.getMessage("hour")},
        "day": {title: Util.getMessage("day")},
        "month": {title: Util.getMessage("month")}
    };

    let sort= "";
    let sortText= "";
    startDate.setDate(endDate.getDate() - 360);
    const defaultSelected = {
        from: startDate,
        to: endDate
      };

    
    const [range, setRange] = useState(defaultSelected);
    const [rangeCustom, setRangeCustom] = useState(null);

    const setSortOrder = (rangeAux) => {
        if(filter){
            filter[fromText] = rangeAux.from.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}) + "T00:00:01";
            filter[toText] = rangeAux.to.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59";
            if(isIntervalShow){
                filter["interval"] = rangeAux.interval;
            }
            if (setFilter) {
                setFilter(filter);
            }
        }
    }

    const [count, setCount] = useState(1);
    
    const setRangeClick = (ran) =>{
        if(ran && ran.from && ran.to){
            setRangeCustom(ran);
            setSelectedDate(-1);
            setInterval("custom"+count);
            setCount(count+1);
        }

    }
    
    useEffect(() => {
        let rangeAux;
        if(selectedDate !== -1){
            let startDate = new Date();
            let endDate = new Date();
            startDate.setDate(endDate.getDate() - selectedDate);
            rangeAux = {
                from: startDate,
                to: endDate
            };
            if(isIntervalShow){
                rangeAux["interval"] = interval
            }
            setRange(rangeAux);
            setSortOrder(rangeAux);
        }else{
            
            let startDate = new Date(rangeCustom.from);
            let endDate = new Date(rangeCustom.to);
            
            let rangeAux = {
                from: startDate,
                to: endDate
            };
            if(isIntervalShow){
                rangeAux["interval"] = interval
            }
            setRange(rangeAux);
            setSortOrder(rangeAux);
        }
        
    },[selectedDate, interval]);  
       
      return (
        <div className={'filters-content content-flex ' + className}>
            <button onClick={() => setIsOpen(!isOpen)} className="btn-filter content-flex flex-center">
                <img src='img/calendar.png' className="img-b bup" />
                <span>{days[selectedDate] ? days[selectedDate].title : Util.getMessage("Filter")}</span>
            </button>
            <div>
                {sort !== "" && (
                    <div className="filters-apllied" onClick={() => this.clearSort()}>
                        <i className="icofont-navigation"></i> {sortText}
                        <i className="icofont-close-squared-alt"></i>
                    </div>  
                )}
            </div>
            {isOpen && (
                <div className="filter">
                    <div><button onClick={() => setIsOpen(!isOpen)} className="close-icon"><i className="icofont-close-circled"></i></button></div>
                    <div className='content-flex'>
                        <div>
                            <ul>
                                {Object.keys(days).map((day, key) => (
                                    <li key={key} onClick={() => {setSelectedDate(days[day].day);setIsOpen(!isOpen)}} className={(days[day].day === selectedDate) ? 'selected' : ''}>{days[day].title}</li>
                                ))}
                            </ul>
                            {isIntervalShow ? (
                                <>
                                    <hr/>
                                    <ul>
                                        {Object.keys(intervalList).map((day, key) => (
                                            <li key={key} onClick={() => {setInterval(day);}} className={(day === interval) ? 'selected' : ''}>{intervalList[day].title}</li>
                                        ))}
                                    </ul>
                                </>) : null}
                        </div>
                        <DayPicker
                            id="test"
                            mode="range"
                            locale={es}
                            toDate={new Date()}
                            selected={range}
                            numberOfMonths={2}
                            onSelect={setRangeClick}
                        />
                    </div>
                </div>

            )}    
        </div>
            
      );

}

export default Filter;
/*
class Filter extends Component {

    state = {
        isOpen: false,
        startDate: null,
        endDate: new Date(),
        focusedInput: null,
        timeInitial: "T00:00:00-05:00",
        timeFinish: "T23:59:59-05:00",
        selectedDate: null,
        sort: "",
        sortText: "",
        sortValue: "",
        customerId: "",
        carrier: "0",
        idCarrier:"",
        filter: null

    };
    componentDidMount() {
        
        const { timeInitial=false, timeFinish=false, initialDate, finishDate } = this.props;
        
        if(timeInitial !== false && timeFinish !== false){
            console.log("Entro");
            this.setState({timeInitial: timeInitial, timeFinish: timeFinish});
        }

        if(initialDate && finishDate){
            this.setSortOrder(initialDate, finishDate, false);
        }

        const { filter } = this.props;
        this.setState({filter: filter, selectedDate: filter.selectedDate});

    }

    setSortBy = (sort) => {
        const { handleSort } = this.props;

        let sortValue = sort;
        let sortText = sort;

        if(sort !== ""){
            let value = sort.split("-");
            sortValue = value[0];
            sortText = value[1];
        }

        if(handleSort){
            handleSort(sortValue);
        }
        this.setState({sort: sortValue, sortText : sortText, sortValue: sort});
    }

    setTranspoter = (sort) => {
        const { handleCarrier } = this.props;

        if(handleCarrier){
            handleCarrier(sort);
        }
        this.setState({idCarrier: sort});
    }

    clearSort = () => {
        this.setSortBy("");
    }

    clearSetDate = () => {
        this.setSortOrder("", "");
    }

    setDate = (initial, finish) => {
       if (initial && finish){
        this.setSortOrder(initial, finish);
       }else{
        this.setState({startDate: initial, endDate: finish});
       }
    }

    setSortOrder = (initial, finish, isCallback=true) => {
        const { filter } = this.state;
        const { setFilter } = this.props;
        
        if (initial !== ""){
            filter.fromUpdateDate = initial + "T00:00:01";
        }else{
            filter.fromUpdateDate = null;
        }
        if (finish !== ""){
            filter.toUpdateDate = finish+ "T23:59:59";
        }else{
            filter.toUpdateDate = null;
        }
        filter.selectedDate = initial;
        this.props.dispatch({
            type: 'CHANGE_FILTER',
            filter: filter        
        });
        this.setState({selectedDate: initial, startDate: initial, endDate: finish, filter: filter});

        if (setFilter) {
            setFilter(filter);
        }
    }

    getText = () => {
        const {  today, yesterday, lastWeek, lastMonth } = this.props;
        const {  startDate, endDate, selectedDate } = this.state;
        let text = "desde el inicio";
        if (selectedDate === today) {
            text = Util.getMessage("Today");
        } else if (selectedDate === yesterday) {
            text = Util.getMessage("Yesterday");
        } else if (selectedDate === lastWeek) {
            text = Util.getMessage("in_Last_week");
        } else if (selectedDate === lastMonth) {
            text = Util.getMessage("in_Last_month");
        } else if (startDate && endDate){
            text = `${startDate.calendar()} - ${endDate.calendar() === "Today at 12:00 AM" ? Util.getMessage("Today") : endDate.calendar()}`;
        }
        return text;
    }

    updateCustomer = (value, name) =>{
        this.setState({customerId: value});
    }

    handleFilterCustomerId = ( name ) => {
        const { handleFilterAdmin } = this.props;
        const { customerId } = this.state;
        if(handleFilterAdmin){
            handleFilterAdmin(customerId, name);
        }
    }

    handleChange = (value, name) => {
        const { handleFilterAdmin } = this.props;
        this.setState({carrier: value});
        if(handleFilterAdmin){
            handleFilterAdmin(value, name);
        }
    }

    render() {
        const { optionsSortBy, today, yesterday, lastWeek, lastMonth, lastYear, isHome=false, handleCarrier=false, filter } = this.props;
        const { isOpen, startDate, endDate, selectedDate, sort, sortText, sortValue, customerId, carrier, idCarrier } = this.state;
        return (
                <>
                    <div className="filters-content content-flex">
                        <button onClick={() => this.setState({isOpen : !isOpen})} className="btn-filter content-flex flex-center">
                            <span>{Util.getMessage("Filter")}</span>
                            <img src='img/up.png' className="img-b bup" />
                            <img src='img/up.png' className="img-b bdown" />
                        </button>
                        <div>
                            {selectedDate && (
                                <div className="filters-apllied" onClick={() => this.clearSetDate()}>
                                    <i className="icofont-calendar"></i>
                                    <>
                                        {selectedDate === today ? (
                                            <span>{Util.getMessage("Today")}</span>
                                        ) : selectedDate === yesterday ? (
                                            <span>{Util.getMessage("Yesterday")}</span>
                                        ) : selectedDate === lastWeek ? (
                                            <span>{Util.getMessage("Last_week")}</span>
                                        ) : selectedDate === lastMonth ? (
                                            <span>{Util.getMessage("Last_month")}</span>
                                        ) : selectedDate === lastYear ? (
                                            <span>{Util.getMessage("Last_year")}</span>
                                        ) : (
                                            <span>{startDate.calendar()} - {endDate.calendar() === "Today at 12:00 AM" ? Util.getMessage("Today") : endDate.calendar()}</span>
                                        )}
                                    </> 
                                    <i className="icofont-close-squared-alt"></i>
                                </div>   
                            )}
                        </div>
                        <div>
                            {sort !== "" && (
                                <div className="filters-apllied" onClick={() => this.clearSort()}>
                                    <i className="icofont-navigation"></i> {sortText}
                                    <i className="icofont-close-squared-alt"></i>
                                </div>  
                            )}
                        </div>
                        
                    </div>
                    {isOpen && (
                        <div className="filter">
                            <div><button onClick={() => this.setState({isOpen : !isOpen})} className="close-icon"><i className="icofont-close-circled"></i></button></div>
                            <h5>Fecha</h5>   
                            <div>
                                <button onClick={() => this.setSortOrder(today, today)} className="btn-filter content-flex flex-center">
                                    <span>{Util.getMessage("Today")}</span>
                                    <img src='img/up.png' className="img-b bup" />
                                    <img src='img/up.png' className="img-b bdown" />
                                </button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(yesterday, yesterday)} className="btn-filter content-flex flex-center">
                                    <span>{Util.getMessage("Yesterday")}</span>
                                    <img src='img/up.png' className="img-b bup" />
                                    <img src='img/up.png' className="img-b bdown" />
                                </button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(lastWeek, today)} className="btn-filter content-flex flex-center">
                                    <span>{Util.getMessage("Last_week")}</span>
                                    <img src='img/up.png' className="img-b bup" />
                                    <img src='img/up.png' className="img-b bdown" />
                                </button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(lastMonth, today)} className="btn-filter content-flex flex-center">
                                    <span>{Util.getMessage("Last_month")}</span>
                                    <img src='img/up.png' className="img-b bup" />
                                    <img src='img/up.png' className="img-b bdown" />
                                </button>       
                            </div>  
                            <div>
                                <button onClick={() => this.setSortOrder(lastYear, today)} className="btn-filter content-flex flex-center">
                                    <span>{Util.getMessage("Last_year")}</span>
                                    <img src='img/up.png' className="img-b bup" />
                                    <img src='img/up.png' className="img-b bdown" />
                                </button>       
                            </div>   
                            <hr></hr>
                            {optionsSortBy && (
                                <>
                                    <h5>Ordenar</h5>
                                    <select className="select-text" value={sortValue} onChange={e => this.setSortBy(e.target.value)}>
                                        <option value="">Ordenar</option>
                                        {optionsSortBy && optionsSortBy.map(
                                            (sort, index) => (
                                                <option key={index} value={`${sort.value}-${sort.title}`}>{sort.title}</option>
                                            )
                                        )}
                                    </select>
                                    <hr></hr>
                                </> 
                            )}
                           <DayPicker
      id="test"
      mode="range"
      defaultMonth={pastMonth}
      selected={range}
      footer={footer}
      onSelect={setRange}
    /> 
                        </div>
                        
                    )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    lastYear: state.lastYear,
    user: state.user,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);
*/