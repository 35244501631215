import React, { useState, useEffect } from 'react';

import * as Util from '../data/General';
import {SUCCESS} from '../components/svg/animate';
import Button from '../components/html/Button';


function Spreadsheet(props) {
  const { setCSV } = props;
  const [file, setFile] = useState();
  const [fil, setFil] = useState();
  const [array, setArray] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isOk, setIsOk] = useState(false);
  const [text, setText] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [droppedFiles, setDroppedFiles] = useState(false);

  const validate = ["number", "string","string", "string"];
  const shopJson =  Util.getAdminShop();

  const fileReader = new FileReader();
  let $form = null;

  

  const handleOnChange = (e, isFile=false, file=null) => {
    let fil = !isFile ? e.target.files[0] : file;
    setFile(fil);
    let fi = fil;
    if (fi) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text, fil);
      };

      fileReader.readAsText(fi);
    }
  };

  const csvFileToArray = (string, fil) => {
    let ext = fil.name.split(".").pop();
    if(ext !== "csv"){
      let errors = [];
      errors.push("error_spreadsheet_0");
      setErrors(errors);
      return;
    }

    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    let errors = [];
    let count = 0;
    let array = csvRows.map(i => {
      const values = i.split(",");
      let isEmpty = true;
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        if(index <= validate.length){
          
        
          if((validate[index] === "number" && (values[index] === "" || (/\D/.test(values[index])))) 
            || (validate[index] === "string" && values[index] === "")){
            errors[count]="error_spreadsheet_"+(index+1);
          }
        }
        count++;
        if(index < 2 && values[index]){
          isEmpty = false;
        }
        return object;
      }, {});

      if(!isEmpty){
        return obj;
      }
    });
    array = array.filter(Boolean);
    console.log("array", array);
    setArray(array);
    setErrors(errors);

    if(errors.length === 0 && array.length > 0){
      setIsOk(true);
      console.log("file", fil);
      //setCSV(fil, array.length);
      setFil(fil);
      //Util.createAudiences(text, array.length, callback, callbackError, fil)
    }
  };

  const createList = () => {
    Util.createAudiences(text, array.length, callback, callbackError, fil)
  }

  const callback = (data) => {
    console.log("llamo a setCSV", data);
    setText("");
    setIsOk(false);
    setIsSuccess(true);
    setArray([]);
    setTimeout(function(){setIsSuccess(false);}.bind(this), "15000");
    setCSV(data);
    
    /*{
    "id": "4iyn8JABvUt8jzVSfAjG",
    "name": "Seba",
    "type": "CSV",
    "total": 2,
    "shopId": "60770517158",
    "createdAt": "2024-07-26T15:07:06"
} */
    /*if (data && data.id) {
      console.log("file", fil);
      Util.uploadFile(fil, fil.name ,data.id, fileOk, fileError); 
    }*/
  };

  const fileOk = (data) => {
    
  };

  const fileError = (data) => {
    
  };

  const callbackError = (data) => {
    
  };

  const handleOnDragOver = (e, add, del) => {
    e.preventDefault();
    e.stopPropagation();
    if(add !== ""){
      $form.classList.add(add);
    }
    if(del !== ""){
      $form.classList.remove(del);
    }
  };

  useEffect(() => {
    $form = document.getElementById("form");
    if($form){
      $form.addEventListener('dragenter', function(e) {
        handleOnDragOver(e,'is-dragover', "");
      });
      $form.addEventListener('dragover', function(e) {
        handleOnDragOver(e,'is-dragover', "");
      });
      $form.addEventListener('dragleave', function(e) {
        handleOnDragOver(e,'', "is-dragover");
      });
      $form.addEventListener('dragend', function(e) {
        handleOnDragOver(e,'', "is-dragover");
      });
      $form.addEventListener('drop', function(e) {
        
        setDroppedFiles(e.dataTransfer.files);
        //let $csvFileInput = document.getElementById("csvFileInput");
        //$csvFileInput.files = e.dataTransfer.files;
        console.log("e.dataTransfer.files[0]", e.dataTransfer.files[0]);
        handleOnChange(e, true, e.dataTransfer.files[0]);
        console.log("e", e);
        handleOnDragOver(e,'', "is-dragover");
      });
    }
  },[]);

  //const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div>
      <h3>{Util.getMessage("hearings_cvs_title2")}</h3>
      <label>{Util.getMessage("hearings_cvs_sub_title")}</label>
      <div className='content-cvs'>
        <div>
              <>
              <form id="form">
              <label>{Util.getMessage("hearings_cvs_input")}</label>
                <input className='input-form' placeholder={Util.getMessage("hearings_cvs_placeholder")} value={text} onChange={e => setText(e.target.value)} />
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  name="csvFileInput"
                  accept={".csv"}
                  onChange={handleOnChange}
                  style={{display:"none"}}
                />
                <label htmlFor="csvFileInput" className='label-file'>
                    <div>
                      <span className="formbold-drop-file"> {Util.getMessage("DropFile")}</span>
                      <span className="formbold-or"> O </span>
                      <span className="formbold-browse"> {Util.getMessage("SelectFile")} </span>
                    </div>
                </label>
                <Button value={Util.getMessage("bt_list")} type="primary" disabled={text === "" || array.length === 0} onClick={() => {createList()}}/>
              </form>
              {isOk ? (
              <>
                <p>{Util.getMessage("CantFile")}: {array.length}</p>
                <label style={{fontSize: "13px"}}><small>{Util.getMessage("confirm_campaign_7")}</small></label>
              </>
              ) : isSuccess ? (
                <div className='success-content' style={{marginTop: "2rem"}}>
                    <div className='content-flex flex-start'>
                      <div dangerouslySetInnerHTML={{__html: SUCCESS}} /> <p dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_ok")}} />
                    </div>
                  </div>
                
              ) : null}
              </>
              

            {errors.length > 0 ? (     
            <div className='errors-spread'>
              <h3>{Util.getMessage("Errors")}</h3>
              {errors.map((val, index) => (
                  <p key={index}>{Util.getMessage(val)}</p>
                ))}
            </div>
            ) : null }
        </div>
        <div className='linkstemplate '>
          <div dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_help")}}></div>
          <div dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_help_1")}}></div>
          <div dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_help_2")}}></div>
          <div dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_help_2.bis")}}></div>
          <table className='cvsformat'>
            <tr>
              <th>phone,first_name,last_name,country_code</th>
            </tr>
            <tr>
              <td>1234567890,Jose,Perez,{shopJson.countryCode}</td>
            </tr>
            <tr>
              <td>1234567891,Maria,Lopez,{shopJson.countryCode}</td>
            </tr>
          </table>
          <div dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_cvs_help_3")}}></div>
          <a href="https://help.revie.ai/es/article/como-seleccionar-la-audiencia-de-tu-campana-1n88rfy/" target='_blank' >{Util.getMessage("spreadsheet_help")}</a>
          <a href="/data/template_campaign.csv" target='_blank' >{Util.getMessage("spreadsheet_example")}</a>
        </div>
      </div>
    </div>
        
  );
}

export default Spreadsheet;