import React, { useState, useEffect } from 'react';

import * as Util from '../data/General';
import Notify from '../components/html/Notify';
import Button from '../components/html/Button';
import * as Constants from './../store/index';

import axios from "axios";
import UploadImages from '../components/html/UploadImages';

function ConfigWhatsapp(props) {

  const [notify, setNotify] = useState("");
  const [step, setStep] = useState(-1);
  const [phone, setPhone] = useState("");
  const [waba_id, setWaba_id] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState(null);
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [returnPhone, setReturnPhone] = useState(null);
  const [accountReturnPhone, setAccountReturnPhone] = useState(null);

  const getAccount = () =>{
    setLoading(true);
    let shop =  Util.getAdminShop();
    let newWA = {};
    if(shop && shop.marketingCredential){
      newWA.marketingCredential = shop.marketingCredential;
    }
    const url = window.url_server_2+"facebook/subaccount/?shop="+Util.getShopDomain();
    newWA["accessTokenStore"] = Constants.getAccessTokenStore();
    axios.post(url, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          console.log(data);
          let account = JSON.parse(data.data);
          console.log("account", account);
          setAccount(account);
          if(account && account.waba_id === ""){
            getPhone();
          }else{
            if(account.mg === ""){
              setStep(1);
              setLoading(false);
            }else{
              setName(account.profile_name);
              setAddress(account.address);
              setEmail(account.emails);
              setLogo(account.logo_url);
              setUrl(account.websites);
              setDescription(account.description);
              setAbout(account.profile_name);
              if(account.returnphone && account.returnphone !== "" ){
                setAccountReturnPhone(account.returnphone);
              }
              setStep(2);
              setLoading(false);
            }
          }
          
        }else{
          alert("Ocurrio un error al obtener la cuenta, comuniquese con Revie!");
        }
    });
  }

  const getPhone = () =>{
    let shop =  Util.getAdminShop();
    let newWA = {};
    if(shop && shop.marketingCredential){
      newWA.marketingCredential = shop.marketingCredential;
    }
    const url = window.url_server_2+"facebook/phone/?shop="+Util.getShopDomain();
    newWA["accessTokenStore"] = Constants.getAccessTokenStore();
    axios.post(url, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          console.log(data);
          let phone = data.data;
          console.log(phone);
          setPhone(phone);
          setStep(0);
          setLoading(false);
        }else{
          alert("Ocurrio un error al obtener el telefono, comuniquese con Revie!");
        }
    });
  }

  const setWaba = (waba_id) =>{
    let shop =  Util.getAdminShop();
    let newWA = {};
    if(shop && shop.marketingCredential){
      newWA.marketingCredential = shop.marketingCredential;
    }
    const url = window.url_server_2+"facebook/setphone/?shop="+Util.getShopDomain();
    newWA["accessTokenStore"] = Constants.getAccessTokenStore();
    newWA["phone"] = phone;
    newWA["waba_id"] = waba_id;
    axios.post(url, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          console.log(data);
          setStep(1);
          setLoading(false);
        }else{
          alert("Ocurrio un error al setear el waba, comuniquese con Revie!");
        }
    });
  }

  const setSender = () =>{
    setLoading(true);
    let shop =  Util.getAdminShop();
    let newWA = {};
    if(shop && shop.marketingCredential){
      newWA.marketingCredential = shop.marketingCredential;
    }
    const urlSever = window.url_server_2+"facebook/senders/?shop="+Util.getShopDomain();
    newWA["accessTokenStore"] = Constants.getAccessTokenStore();
    
    newWA["name"] = name;
    newWA["address"] = address;
    newWA["emails"] = email;
    newWA["logo_url"] = logo;
    newWA["description"] = description;
    newWA["about"] = about;
    newWA["websites"] = url;
    newWA["returnphone"] = returnPhone;
    axios.post(urlSever, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          console.log(data);
          setNotify(Util.getMessage("exist_sender"));
          setLoading(false);
        }else{
          alert("Ocurrio un error al crear el sender, comuniquese con Revie!");
        }
    });
  }

  

  useEffect(() => {
    if(step === 0){
      setTimeout(function(){window.loadFacebook();}.bind(this), "1200");
      window.intervalSet = setInterval(function(){
          if(window.waba_id){
            setWaba_id(window.waba_id);
            clearInterval(window.intervalSet);
            window.intervalSet = null;
            setWaba(window.waba_id);
          }
        }.bind(this), 3000);

      /*window.intervalSetCode = setInterval(function(){
        if(code){
          clearInterval(window.intervalSetCode);
          window.intervalSetCode = null;
        }else{
          getCode();
        }
      }.bind(this), 5000);*/
    }else if(step === -1){
      getAccount();     
    }else{
      if(window.intervalSet){
        clearInterval(window.intervalSet);
        window.intervalSet = null;
      }
      if(window.intervalSetCode){
        clearInterval(window.intervalSetCode);
        window.intervalSetCode = null;
      }
    }
    if(returnPhone === null){
      const input = document.querySelector("#phone");
      if(input){
        window.setPhoneInput();
        setReturnPhone("");
        window.intervalSetInput = setInterval(() => {setReturnPhoneInput();}, 3000);
        if(accountReturnPhone){
          setTimeout(() => {
            const iti = window.intlTelInput.getInstance(input);
            iti.setNumber(accountReturnPhone);
          }, 2000);
          setReturnPhone(accountReturnPhone);  
        } 
      }
    }

    return () => {
      if(window.intervalSet){
        clearInterval(window.intervalSet);
        window.intervalSet = null;
      }
      if(window.intervalSetInput){
        clearInterval(window.intervalSetInput);
        window.intervalSetInput = null;
      }
      if(window.intervalSetCode){
        clearInterval(window.intervalSetCode);
        window.intervalSetCode = null;
      }
    }
  },[step, code]);

  const getCode = () => {
    const url = window.url_server_2+"facebook/getcode/";
    let newWA = {"account": account.sid};
    axios.post(url, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          setCode(data.data);
        }
    });
  }

  const onclick = () => {
    window.launchEmbeddedSignup();
  }

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    
    setNotify(Util.getMessage("Copied"));
  }
  const save = () => {
    setSender();
  }
  const onBlurLogo = (value, name) => {
    setLogo(value);
  }
  const setReturnPhoneInput = () => {
    
    const input = document.querySelector('#phone');
    //console.log(input.value);
    if(input && input.value === "") return;
    const iti = window.intlTelInput.getInstance(input);
    
    if(iti.isValidNumber()){
      setReturnPhone(iti.getNumber());
    }
  }
 

  return (
    <div className="content-config conf-whatsapp" style={{maxWidth: "850px", paddingBottom: "10rem"}}>
      {notify !== "" && (
          <Notify text={notify} />
      )}
      <div className='title'>{Util.getMessage("confg_whatsapp_title")}</div>
      {step === -1 ? (
        <div style={{width: "100%",height: "100px",display: "flex",justifyContent: "center"}}><div className="preloaderHtml"></div></div>
      ) : step === 0 ? (
        <>  
        <div className='sub-title content-flex'><div className='number'>1</div>{Util.getMessage("cf_text_0")}</div>
          <div className='text'>{Util.getMessage("cf_text_1")}</div>
          <div className='text content-flex flex-center g8px'>{Util.getMessage("cf_text_1_input")}: 
            <b>{phone}</b>
            {/*<button className="button quarter" onClick={() => copy(phone)} >
                <span className="tooltiptext" id="myTooltip">{Util.getMessage("clipboard")}</span>
            </button>*/}
          </div>
          {code ? (
            <>
              <div className='text content-flex flex-center g8px'>{Util.getMessage("cf_text_1_input_code")}: 
              <b>{code}</b>
              <button className="button quarter" onClick={() => copy(code)} >
                  <span className="tooltiptext" id="myTooltip">{Util.getMessage("clipboard")}</span>
              </button></div>
            
            </>
          ): null}
          <hr/>

          
          <div className='text'>{Util.getMessage("cf_text_2")}</div>
          <div className='text content-flex flex-center g8px'>
            <button onClick={() => onclick()} style={{backgroundColor: "#1877f2", border: "0", borderRadius: "4px", color: "#fff", cursor: "pointer", fontFamily: "Helvetica, Arial, sans-serif", fontSize: "16px", fontWeight: "bold", height: "40px", padding: "0 24px"}}>
              {Util.getMessage("login_with_facebook")}
            </button>
          </div>
          <div className='text'>{Util.getMessage("cf_text_3")}</div>
        </>
      ) : (step === 1 || step === 2) ? (
        <>
          {step === 1 ? (
            <>
              <div className='sub-title content-flex'><div className='number'>2</div>{Util.getMessage("cf_text_step_2")}</div>
              <div className='text'>{Util.getMessage("cf_text_4")}</div>
            </>
          ) : null}
          <div className='text'>
              <h2>{Util.getMessage("cf_text_6_input")}</h2>
              <input type="text" className="input-form" value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className='text'>
            <h2>{Util.getMessage("cf_text_7_input")}</h2>
            <UploadImages onBlur={onBlurLogo} name="logo" formatcontrol={"PNG o JPG o JPEG"} square={true} minWidth="640" minHeight="640" value={logo} isPreview={true} text_btn={"btn_change_image"}/>  
          </div>
          <p className="text-small" dangerouslySetInnerHTML={{__html: Util.getMessage("logo_small") }} />
          <div className='text'>
              <h2>{Util.getMessage("cf_text_11_input")}</h2>
              <input type="text"  className="input-form" value={about} onChange={e => setAbout(e.target.value)} />
          </div>
          <div className='text'>
              <h2>{Util.getMessage("cf_text_5_input")}</h2>
              <input type="text" className="input-form" value={description} onChange={e => setDescription(e.target.value)} />
          </div>
          <div className='text'>
              <h2>{Util.getMessage("cf_text_8_input")}</h2>
              <input type="text" className="input-form"  value={url} onChange={e => setUrl(e.target.value)} />
          </div>
          <div className='text'>
              <h2>{Util.getMessage("cf_text_9_input")}</h2>
              <input type="email"  className="input-form" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='text'>
              <h2>{Util.getMessage("cf_text_10_input")}</h2>
              <input type="text"  className="input-form" value={address} onChange={e => setAddress(e.target.value)} />
          </div>
          <div className='text'>
              <h2>{Util.getMessage("cf_text_12_input")}</h2>
              <input className="input-form" id="phone" type="tel"/>
          </div>

          <div style={{"marginTop": "1rem"}} className='content-flex flex-start'>
            <Button value={Util.getMessage("SAVE")}  disabled={loading  || url === ""  || (!url.startsWith("https://")) || logo === "" || name === "" || about === "" || returnPhone === "" } type="primary" onClick={() => save()}/>
            {loading ? <div className="preloaderHtml" style={{marginLeft: "7rem"}}></div> : null}
          </div>
        </>
      ) : null}
    </div>
);
}
export default ConfigWhatsapp;