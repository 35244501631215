import React, { useState, useEffect } from 'react';
import SelectDescriptions from '../html/SelectDescriptions';
import * as Util from './../../data/General';
import Button from '../html/Button';
function Tabs(props) {
  const { tabs, value, onChange, theme } = props;
  const [select, setSelect] = useState(0);
  const [name, setName] = useState("tabs_"+Math.random());
  
  useEffect(() => {
    
  },[select]);

 
  return (
      <div class="tabs">
        {tabs && tabs.map((tab, key) => (
          <React.Fragment key={key}>
          <input class="input" name={name} type="radio" id={`${name}-${key}`} checked={select === key}/>
          <label class="label" for={`${name}-${key}`} onClick={() =>setSelect(key)}>{Util.getMessage(tab.title)}</label>
          <div class="panel">
            <p dangerouslySetInnerHTML={{__html: Util.getMessage(tab.text)}} />
            {tab.values && tab.values.length > 0 ? (
              <p>
                <SelectDescriptions theme={theme} values={tab.values} selected={value[tab.name].value === "false" ? tab.default : value[tab.name].value} onChange={onChange} name={tab.name}/>
              </p>
            ) : tab.isCustom ? (
                  <div className='text'>
                      <Button value={Util.getMessage("TRIGGER_CODE")} type="primary" onClick={() => onChange("true", tab.name)}/>
                  </div>
            ) : null}
          </div>
          </React.Fragment>
        ))}
    </div>
);
}

export default Tabs;