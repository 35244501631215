import React, { useState, useEffect } from 'react';

import * as Util from './../../data/General';
import ChartRevie from './ChartRevie';
import StarList from './StarList';
import ChartProducts from './ChartProducts';

const COLORS_DEFINET = ["#49A0FD", "#53E8A6", "#FFBC4B", "#FF6478", "#8B76D8", "#6E848E", "#54B4A9", "#DB40EC",'#3B93A5'];
const COLORS_DEFINET2 = ['#F7B844',
'#ADD8C7',
'#EC3C65',
'#CDD7B6',
'#C1F666',
'#D43F97',
'#1E5D8C',
'#421243',
'#7F94B0',
'#EF6537',
'#C0ADDB'];


function ChartRequest(props) {
  const { url, delay, isDataCheck=false, dataCheck=null,title, yAxis="", isAverage=false, isZero = true, isPay, typeLabel="normal", typeValue="normal", max=90, dataLabels=false, name="", type = 'line', pre_label="", listColors } =  props;
  let { listValues, checkExtra="NOT_SENT", messageExtra="menssage_not_send" } =  props;
  const [charts, setCharts] = useState(null);
  const [isData, setIsData] = useState(true);
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
  const dataOk = (dataServer) => {
    let data = [];
    let categories = [];
    let colors = [];
    let count = 0;
    let total = 0;
    let extra = null;
    const options = {
      month: "2-digit",
      day: "2-digit",
    };
    for (const id in dataServer) {
        if(max > count){
            let element = dataServer[id];
            if(type !== "treemap" && (isZero || element.count != 0)){
              
              let value =element.count;
              if(typeValue === "fixed2"){
                total = total + value;
                value = value.toFixed(2);
              }else if(typeValue === "int"){
                value = parseInt(value);
                total = total + value;
              }else if(typeValue === "int2"){
                value = parseInt(value);
              }
              if(listValues){
                Object.keys(listValues).map(
                  (list, index) => {
                    if(listValues[list].list.search(","+element.key+",") > -1){
                      listValues[list].count = listValues[list].count + value;
                    }
                  });
                  if(checkExtra === element.key){
                    extra = value;
                  }
              }else{
                data.push(value);
                if(typeLabel === "normal"){
                  categories.push(element.key);
                }else if(typeLabel === "traductor"){
                  categories.push(Util.getMessage(pre_label+element.key));
                }else if(typeLabel === "date"){
                  var today  = new Date(element.key);
                  categories.push(new Intl.DateTimeFormat('en-US').format(today));
                }
                if(type === 'bar'){
                  colors.push(COLORS_DEFINET[count]);
                } else if(type === 'pie'){
                  colors.push(listColors[element.key]);
                } else if(type === 'donut' && listColors){
                  colors.push(listColors[element.key]);
                }
              }
              

              
              count++;
            }else if(type==="treemap"){
              //{key: "Excelente", count: 21}
              data.push({
                x: element.key,
                y: element.count
              });
              colors.push(COLORS_DEFINET2[count]);
              count++;
            }
        }
    }
    setIsData(true);
    if(listValues){
      Object.keys(listValues).map(
        (list, index) => {
          if(listValues[list].count > 0){
            data.push(listValues[list].count);
            categories.push(Util.getMessage(pre_label+list));
            colors.push(COLORS_DEFINET[index]);
          }
        });
    }

    if(data.length === 0 && (type === 'donut' || type === 'pie' || type === 'line' || type === 'bar')){
      setIsData(false);
    }else if(data.length === 0 && type === 'treemap'){
      data.push({
        x: isPay ? "" : Util.getMessage("update_plan"),
        y: 1
      });
      colors.push(COLORS_DEFINET2[2]);
    }

    setCharts({
        data:data,
        categories: categories,
        colors: colors,
        //extra: extra ? Util.getMessage(messageExtra).replace("XXX", new Intl.NumberFormat().format(extra)) : null,
        title: title + (total > 0 ? ": "+ (isAverage ? (total/count).toFixed(2) : (yAxis === "currency" ? USDollar.format(total) : new Intl.NumberFormat().format(total))) : "")
      });
  }

  const dataFail = (data) => {

  }

  const getData = () => {
    Util.getStatistics(dataOk, dataFail, url);
  }

  useEffect(() => {
    if (type !== 'start' && type !== 'ListProducts' && !isDataCheck){
      setTimeout(() => {getData();}, delay);
      console.log("getIntervalStatistics");
    }else if(isDataCheck){
      dataOk(dataCheck);
    }
  },[url]);
  return (
    <>
        {!isData ? (
          <></>
        ) : ((charts && charts.data && charts.data.length > 0) || type === 'start' ||  type === 'ListProducts') ? (
          <>
          {(type === 'line') ? (
            <ChartRevie 
              className="G1" 
              options={{
                xaxis: {categories: charts.categories,type: (typeLabel === "date") ? 'datetime' : null}, 
                title: {text: charts.title},
                chart: {
                    height: 550,
                    zoom: {
                      enabled: false
                    }
                  },
                stroke: {
                    curve: 'smooth',
                    width: 4,
                    colors: '#342BC2'
                  },
                dataLabels: {
                  enabled: dataLabels
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      if(yAxis === "currency"){
                        return USDollar.format(val);
                      }else if(typeValue === "fixed2"){
                        return val.toFixed(2);
                      }
                      return val;
                    }
                  }
                }
              }} 
              series={[{name: name, data: charts.data }]} 
              type = {type}
            />
          ) : (type === 'bar') ? (
            <ChartRevie 
              className="G1" 
              options={{
                xaxis: {categories: charts.categories,type: (typeLabel === "date") ? 'datetime' : null, labels: {
                  show: false
                }}, 
                colors: charts.colors,
                plotOptions: {
                  bar: {
                    columnWidth: '45%',
                    distributed: true,
                  }
                },
                dataLabels: {
                  enabled: false
                },
                title: {text: charts.title},
                chart: {
                    height: 550,
                    zoom: {
                      enabled: false
                    }
                  },
                dataLabels: {
                  enabled: dataLabels
                },
              }} 
              series={[{name: name, data: charts.data }]} 
              type = {type}
              extra = {charts.extra ? charts.extra : null}
            />
            ): (type === 'treemap') ? (
              <ChartRevie 
                className="G1" 
                options={{legend: {
                  show: false
                },
                chart: {
                  height: 350,
                  type: 'treemap'
                },
                title: {
                  text: charts.title,
                },
                colors: charts.colors,
                plotOptions: {
                  treemap: {
                    distributed: true,
                    enableShades: false
                  }
                }
                }} 
                series={[{data: charts.data}]} 
                type = {type}
              />
              ) : (type === 'ListProducts') ? (
              <ChartProducts title={title}/>
            ) : type === 'start' ? (
            <div className='mixed-chart G1'><p className="title-chart">{title}</p><StarList url={url}/></div>
          ) : (type === 'donut' || type === 'pie') && (
            <ChartRevie 
              className="G3" 
              options={{
                labels: charts.categories, 
                /*fill: {
                  type: 'gradient',
                },*/
                colors: charts.colors,
                dataLabels: {
                      formatter(val, opts) {const name = opts.w.globals.labels[opts.seriesIndex]; return [name, val.toFixed(1) + '%']}
                }, 
                title: { text: charts.title},
                legend: {position: 'bottom'}}
              } 
              series={ charts.data} type={type} 
            />
          )}
          </>
        ) : <div className='mixed-chart'>
        <div className="skeleton--graph">
          <div className="skeleton--graph__shimmer">
            <div className="top top--1"></div>
            <div className="sep sep--1"></div>
            
            <div className="top top--2"></div>
            <div className="sep sep--2"></div>
            
            <div className="top top--3"></div>
            <div className="sep sep--3"></div>
            
            <div className="top top--4"></div>
            <div className="sep sep--4"></div>
            
            <div className="top top--5"></div>
            <div className="sep sep--5"></div>
            
            <div className="top top--6"></div>
            <div className="sep sep--6"></div>
            
            <div className="top top--7"></div>
            <div className="sep sep--7"></div>
            
            <div className="top top--8"></div>
            <div className="sep sep--8"></div>
            
            <div className="top top--9"></div>
            <div className="sep sep--9"></div>
            
            <div className="top top--10"></div>
            <div className="sep sep--10"></div>
          </div>
        </div>
      </div>}
    </>
);
}

export default ChartRequest;