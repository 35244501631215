import React, { Component } from 'react';
import * as Util from './../../data/General';

class Social extends Component {

    
    render() {
        const { configSocial, review, isPay } = this.props;

        //console.log(this.hexToRgb(configSocial.background.value));
        //background: rgb(21,89,105);background: linear-gradient(347deg, rgba(21,89,105,1) 0%, rgba(71,187,157,1) 100%);

        let back = {"background": `linear-gradient(347deg, rgba(${Util.hexToRgb(configSocial.backgroundfinish.value)},1) 0%, rgba(${Util.hexToRgb(configSocial.backgroundinic.value)},1) 100%)`}
        console.log("back", back);
        return (
            <div className={`bodySocial ${isPay ? '' : 'image-update-plan'}`} style={back}>
                <div id="contenedor" style={back}>
                    <div className={`content font-${configSocial.font.value}`} style={{"background": configSocial.backgroundCard.value}}>
                        {review.images && review.images.length > 0 ?  (
                            <img src={review.images[0].src} />
                        ) : review.product.images && review.product.images.length > 0 && (
                            <img src={review.product.images[0].src} />
                        )}
                        <div>
                            <h1 className="title" style={{"color": configSocial.colorTitle.value, "lineHeight": configSocial.colorTitle.value+"px","fontSize": configSocial.fontSizeTitle.value+"px"}}>{review.product.name}</h1>
                            <div className="start">
                                <div style={{"color": configSocial.colorStar.value, "fontSize": configSocial.fontSizeStart.value+"px"}}><i data-starlist={review.stars}></i></div>
                            </div>
                            <div className="message" style={{"color": configSocial.colorMessage.value, "fontSize": configSocial.fontSizeMessage.value+"px", "lineHeight": configSocial.fontSizeMessage.value+"px"}}>{review.comment}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
  }
}

export default Social;
