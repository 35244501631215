import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../../data/General';
import * as Constants from './../../store/index';

import mixpanel from 'mixpanel-browser';
import ListProducts from '../widget/ListProducts';

class ChartProducts extends Component {

    state = {
      reviews: null,
      reviewsList: null,
      filterState: null,
      isSearch: false,
      tabSelect: null,
      shop: null,
      stars: "",
      isPay: false,
      isShowProduct: false,
      timeOut:null
    };

    
    componentDidMount() {

      const { filter } = this.props;
      filter.from = 0;
      filter.state = "";
      filter.size = 5;
      filter.sortBy="reviewData.countProduct";
      filter.sortOrder="desc";
      this.setFilter(filter);
      var shopJson =  Util.getAdminShop();
      if (shopJson){

        let isPay = (shopJson.plan && shopJson.plan.planType !== "50" && shopJson.plan.planType !== "0");
        this.setState({shop: shopJson, isPay: isPay});
        mixpanel.init(window.mixpanelKey); 
        mixpanel.track('Products', {
          'shopDomain': shopJson.domain,
          'shopId': shopJson.id,
        });
      }
    }

    setFilter = (filter, isSearch=true) =>{
      this.setState({filterState: filter, isSearch: isSearch});
      Util.getProducts(filter, this.handleOk, this.handleError);
    }

    handleOk = (data) => {
      this.setState({reviews: data, isSearch: false});
    }

    handleError = (data) => {
      //this.setState({reviews: data});
    }

    handleChangePage = (page) => {

      const { filterState } = this.state;
      filterState["size"] = page;
      filterState.from = 0;
      this.setFilter(filterState);
      return false;  
    }  

    goTo = (from) => {
        const { filterState } = this.state;
        filterState.from = from;
        this.setFilter(filterState);
    }

    update = () => {
      const { filterState } = this.state;
      this.setFilter(filterState, false);
    }

    onChangeStar = (value) => {
      const { filterState } = this.state;
      filterState["stars"] = (value !== "" && value !== 0 ? value : null);
      this.setState({stars: value});
      this.setFilter(filterState);
      return false;  
    }


    handleClickTab = (tab) => {
      const { tabs, tabsState, filterState, shop } = this.state;
      this.setState({tabSelectedName: tabs[tab], isSearch: true, tabSelect: tab});
      filterState.state=tabsState[tab];
      Util.getReviews(filterState, this.handleOk, this.handleError);
      /*mixpanel.init(window.mixpanelKey); 
      mixpanel.track('RE '+tabs[tab].toLowerCase(), {
        'shopDomain': shop.domain,
        'shopId': shop.id,
      });*/

    }

    searchFinish = () =>{
      const { filterState, timeOut } = this.state;
      clearTimeout(timeOut);
      if(filterState < 10){
        filterState.size = 15;
      }
      this.setFilter(filterState);
    }

    searchProducts = (text) =>{
      let { timeOut, filterState } = this.state;
      console.log(text);
      filterState["text"] = text;
      clearTimeout(timeOut);
      timeOut = setTimeout(function(){
        this.searchFinish();
      }.bind(this), 800);
      this.setState({timeOut: timeOut, filterState: filterState});
      
      return false;  
    }

    show = (product) => {
    }

    goToList = () => {
      const { reviewsList } = this.state;

      this.setState({reviews: reviewsList, isShowProduct: false});
    }

    setFilterOrder = (sortBy) => {
      const { filterState } = this.state;

      
      if(filterState.sortBy === sortBy){
        if (filterState.sortOrder === "desc" ){
          filterState.sortOrder="asc";
        }else{
          filterState.sortOrder="desc";
        }
      }else{
        filterState.sortBy=sortBy;
      }
      this.setFilter(filterState);
    }
  
    render() {

      const { reviews, isSearch, filterState, isPay, isShowProduct, shop } = this.state;
      const { className, title } = this.props;
      
        return (
          <div className={`mixed-chart ${className ? className : ''}`}>
            <p className="title-chart">{title}</p>
            <div className="content-reviews">
                <div className='content-products'>
                  {isSearch ? (
                    <div className='search'>
                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                  ) : (
                    <>
                      <ListProducts hide={this.goToList} show={this.show} shop={shop} isShowProduct= {isShowProduct} reviews={reviews} isPay={isPay} isShowTitle={false} title={"products_list"} handleOk={this.update} filter={filterState} setFilterOrder={this.setFilterOrder}/> 
                      </>
                  )}
                </div>
            </div>
          </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartProducts);
