import React from 'react';
import Chart from "react-apexcharts";


function ChartRevie(props) {
  
  return (
    
    <div className={`mixed-chart ${props.className ? props.className : ''}`}>
        <Chart
          options={props.options}
          series={props.series}
          type={props.type}
        />
        {props.extra ? (<small className='extra' dangerouslySetInnerHTML={{__html: props.extra}} />) : null}
      </div>
);
}

export default ChartRevie;