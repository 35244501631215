import React, { Component } from 'react';

class Point extends Component {

    render() {
        const { point } = this.props;

        /*return (
            <div className='content-flex points'>
                <div className='active'></div>
                <div className={point > 1 ? 'active' : ''}></div>
                <div className={point > 2 ? 'active' : ''}></div>
                <div className={point > 3 ? 'active' : ''}></div>
                <div className={point > 4 ? 'active' : ''}></div>
            </div>     
        );*/
        return (
            <div className='review star points-icon'>
                <div className='revieStar'>
                    <div title={`${point} estrellas`}>
                        <i data-star={point}></i>
                    </div>
                </div>
            </div>
        );
  }
}

export default Point;