import React, { Component } from 'react';



class Button extends Component {

    
    componentDidMount() {
        
    }

    onClick = () => {
        const { onClick, disabled=false, loader=false } = this.props;
        if (onClick && !disabled && !loader){
            onClick();
        }
    }
  
    render() {
        const { type="second", value, image=false, disabled=false, loader=false, imageInc=false } = this.props;
       
        return (
            <button 
                type="button" 
                onClick={() => this.onClick()} 
                className={`button ${type ? type : ''}`}
                disabled={disabled}
                >
                    {(imageInc && !loader) && (<img src={imageInc} className="img-button-inic" />)}
                    {!loader ? value : null}
                    {(image && !loader) && (<img src={image} className="img-button" />)}
                    {loader && (
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
            </button>
        );
  }
}
    
export default Button;