import React, { useState, useEffect } from 'react';
import * as Util from './../../data/General';
import SelectHtml from './SelectHtml';

const SEARCH = " (ID:";
function ListSegments(props) {
    const { name, onSegments, disabled, value, valueCVS, nameCVS, clear, modo="list", category="all",maxHeight="500px", setCSV } = props;
    const [text, setText] = useState("");
    const [id, setID] = useState("");
    const [type, setType] = useState("");
    const [addClass, setAddClass] = useState("");

    
      const [sids, setSids] = useState(null);
      const [sidsObjet, setSidsObjet] = useState(null);

      const [lists, setLists] = useState(null);
      
      const isOK = (data) => {
          console.log(data);
          setSids(data);
          let obj = {};

          data.map((key) =>  {
              obj[key.id] = key.name;
          });
          setSidsObjet(obj);
      }
  
      const isError = (error) => {
  
      }

      const isErrorList = (error) => {
  
      }

      const isOKList = (data) => {
        console.log(data);
        setLists(data);
      }
  
      useEffect(() => {
          if(sids === null){
            if(category === "all" || category === "segment") updateList("");
            if(category === "all" || category === "list") Util.getList(isOKList, isErrorList);
          }
          if(onSegments && setCSV){
            setAddClass("clickable");
          }
      }, []);
  
    const change = (id, textValue) => {
        if(onSegments && value !== id) onSegments(id, name, textValue + " (" + id+")");
    }
    const changeCVS = (item) => {
        if(setCSV && valueCVS !== item.id) setCSV(item);
    }
    const clearClick = () => {
        if(clear) clear();
    }
    const getValue = (item) =>{
        return item.name + SEARCH + item.id + ")";
    }

    const deleteSegment = (id) => {
        window.confirm(Util.getMessage("confirm_delete_segment")) && Util.deleteSegment(id, updateList, isError);
    }

    const updateList = (data) => {
        Util.getSegments(isOK, isError);
    }
    return (
        <div>
            {/*<input list={name} className='input-form' disabled={disabled} id="input-segment" onChange={e => change(e.target.value)} />
            <select value={value} onChange={e => change(e.target.value)} className='input-form' disabled={disabled} > 
                {sids && sids.map((item, key) => (
                    <option key={key} value={item.id} selected={item.id === value}>{getValue(item)}</option>
                ))}
            </select>*/}

            {disabled && valueCVS ? (
                <div className='list-segments' style={{paddingBottom: "0px"}}> 
                    <div className='head'>{nameCVS}</div>
                </div>
            ) : (modo === "list" && !disabled) ? (
                <>
                <div className='list-segments' style={{zIndex: "3",position: "relative",paddingBottom: "0px"}}> 
                    <div className='head'>Nombre</div>
                    <div className='head'>tipo</div>
                    <div className='head'>Autor</div>
                    <div className='head'>Acción</div>
                    <div className='head-action'><input className='input-form' value={text} onChange={e => setText(e.target.value)} /></div>
                    <div className='head-action'></div>
                    <div className='head-action' style={{padding: "0px"}}><select className='input-form' value={type} onChange={e => setType(e.target.value)}><option value="">Todos</option><option value="-[REVIE-IA]">IA</option><option value="-[REVIE-SEGMENTS]">Manual</option><option value="Shopify">Shopify</option><option value="List">Lista</option></select></div>
                    <div className='head-action'></div>
                </div>
                <div className='list-segments' style={{maxHeight: maxHeight,overflow: "auto",marginTop: "-1rem"}}> 
                    {(category === "all" || category === "list") && lists && lists.map((item, key) => (
                        <>  
                            {!(text === "" || item.name.toUpperCase().includes(text.toUpperCase()))
                            || !(id === "" || ` ${item.id}`.includes(id))
                            || !(type === "" || type === "List") ? null : (
                                <> 
                                    <div className={`${addClass} content-flex space-between-center ${item.id === valueCVS ? "active" : ""}`} onClick={() => changeCVS(item)}>
                                        {item.name} {item.id === valueCVS ? <span className='' onClick={() => clearClick()}>X</span> : null}
                                    </div>
                                    <div className={`${addClass} ${item.id === valueCVS ? "active" : ""}`} onClick={() => changeCVS(item)}>Lista (CSV)</div>
                                    <div className={`image ${item.id === valueCVS ? "active" : ""}`}>
                                        <img src="/img/list.png" alt="List" /> 
                                    </div>
                                    <div className={`image`}></div>
                                    
                                </>
                            )}
                        </>
                    ))}
                    {(category === "all" || category === "segment") && sids && sids.map((item, key) => (
                        <>  
                            {item.name.endsWith("-[REVIE-FLOW]") || !(type !== "List")
                            || !(text === "" || item.name.toUpperCase().includes(text.toUpperCase()))
                            || !(id === "" || ` ${item.id}`.includes(id))
                            || !(type === "" || type === "Shopify" || item.name.endsWith(type))
                            || (type === "Shopify" && (item.name.endsWith("-[REVIE-IA]") || item.name.endsWith("-[REVIE-SEGMENTS]"))) ? null : (
                                <> 
                                    <div className={`${addClass} content-flex space-between-center ${item.id === value ? "active" : ""}`} onClick={() => change(item.id, item.name)}>
                                        {item.name.replace("-[REVIE-IA]", "").replace("-[REVIE-SEGMENTS]", "")}
                                        {item.id === value ? <span className='' onClick={() => clearClick()}>X</span> : null}
                                    </div>
                                    <div className={`${addClass} ${item.id === value ? "active" : ""}`} onClick={() => change(item.id, item.name)}>Segmento Shopify</div>
                                    <div className={`image ${item.id === value ? "active" : ""}`}>
                                        {item.name.endsWith("-[REVIE-IA]") ? <img src="/img/ia/chatgp2.png" alt="Shopify" /> : item.name.endsWith("-[REVIE-SEGMENTS]") ? <img src="/apple-icon-57x57.png" alt="Shopify" /> : <div dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path fill="#95BF47" fill-rule="evenodd" d="m13.489 19.78-.045-17.4c-.117-.119-.346-.083-.436-.057l-.597.186a4.162 4.162 0 0 0-.286-.702C11.703 1 11.086.574 10.338.571h-.003c-.049 0-.1.005-.149.009h-.005l-.068-.077A1.567 1.567 0 0 0 8.868 0c-.967.029-1.93.726-2.71 1.964-.55.872-.965 1.967-1.084 2.815l-1.903.59c-.558.175-.577.192-.65.72C2.464 6.489 1 17.814 1 17.814l12.278 2.123.21-.157h.001ZM9.467.796a.989.989 0 0 0-.583-.153C7.394.686 6.093 3.014 5.76 4.567l1.431-.443.253-.078c.188-.986.659-2.007 1.271-2.666.215-.236.47-.434.751-.584h.002-.001ZM8.15 3.827l2.052-.634c.006-.534-.051-1.324-.319-1.882a2.073 2.073 0 0 0-.697.508c-.46.494-.835 1.25-1.034 2.008H8.15Zm2.693-.834.953-.294c-.152-.495-.512-1.325-1.245-1.463.228.588.286 1.268.292 1.757Z" clip-rule="evenodd"></path><path fill="#5E8E3E" d="M16.24 3.673c-.071-.006-1.492-.027-1.492-.027s-1.188-1.153-1.305-1.27a.298.298 0 0 0-.165-.076v17.633L18.6 18.61 16.415 3.836a.194.194 0 0 0-.174-.163h-.001Z"></path><path fill="#fff" d="m10.33 6.411-.62 2.312s-.688-.314-1.506-.262c-1.198.076-1.211.832-1.2 1.02.066 1.035 2.786 1.26 2.94 3.682.12 1.904-1.01 3.208-2.64 3.311-1.957.123-3.034-1.031-3.034-1.031l.415-1.762s1.083.818 1.95.763a.77.77 0 0 0 .75-.823c-.086-1.348-2.3-1.268-2.441-3.485-.117-1.865 1.107-3.753 3.81-3.925 1.042-.065 1.574.2 1.574.2h.002Z"></path></svg>`}} />}
                                    </div>
                                    
                                    <div className={`image click`} onClick={() => deleteSegment(item.id)}>
                                        <img src="/img/delete.png" alt="List" /> 
                                    </div>
                                </>
                            )}
                        </>
                    ))}
                </div>
                </>
            ) : (
                <SelectHtml value={value} disabled={disabled} values={sids} defaultText={"Seleccione"} defaultValue={""} onChange={change}/>
            )}


        </div>
    );
}
    
export default ListSegments;
