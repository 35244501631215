import React, { Component } from 'react';
import * as Util from './../../data/General';



class SelectHtml extends Component {
    state = { value: "", mError:"" };

    componentDidMount() {
        
    }

    onChange = (value) => {
        const { name, onChange } = this.props;
        
        if (onChange){
            onChange(value, name);
        }
        
    }
  
    render() {
        const { options, value, isKey=true,edit=true, values, valuesIn, keyValue, defaultValue, defaultText, className="" , loading, disabled=false, simpleValues } = this.props;
        const { mError } = this.state;
        return (
            <div className={className}>
                {edit ? (
                    <div>
                        {loading && (<div className="preloaderHtml"></div>)}
                        <select className={`form-control input-form ${mError !== "" && 'error'}`} value={value != null ? value : ""} disabled={disabled} onChange={e => {this.onChange(e.target.value); console.log(e.target)}} >
                            {defaultText && (
                                <option value={defaultValue}>
                                    {defaultText}
                                </option>
                            )}
                            {options && Object.keys(options).map((key, index) =>
                                <option key={index} value={isKey ? key : options[key]}>
                                    {isKey ? Util.getMessage(options[key]) : options[key]}
                                </option>
                            )}
                            {values && values.map((key, index) =>
                                <option key={index} value={key.id}>
                                    {key.name} {isKey ? "("+key.id+")" : null}
                                </option>
                            )}
                            {simpleValues && simpleValues.map((key, index) =>
                                <option key={index} value={key}>
                                    {key}
                                </option>
                            )}
                            {valuesIn && valuesIn.map((key, index) =>
                                <option key={index} value={key.value}>
                                    {Util.getMessage(key.label)}
                                </option>
                            )}

                        </select>
                        {mError !== "" && (<div className="error">{mError}</div>)}
                    </div>    
                ) : (
                    <label className="mode-edit">
                        {defaultText && value === defaultValue && defaultText}
                        
                        {keyValue ? 
                             this.getLabel(options[value], value)
                        : 
                            options[value]
                        }
                    </label>
                )}  
            </div> 
        );
  }
}

export default SelectHtml;