import React, { useState, useEffect } from 'react';

import * as Util from './../../data/General';

function SelectDescriptions(props) {
  const { values, selected, onChange, name, theme } = props;
  const [value, setValue] = useState(null);
  const [label, setLabel] = useState(null);
  const [show, setShow] = useState(false);

  let upperTheme = theme;
  if (upperTheme != null){
    upperTheme = upperTheme.toUpperCase();
  }
  
  useEffect(() => {
    let isOptions = false;
    {values && values.map((val, index) => {
        if(val.value === selected){
          setValueNotific(val);
          isOptions = true;
        }
    })}

    if(!isOptions && !value && values && values.length > 0) {
      setValueNotific(values[0]);
    }
  },[]);

  const setValueNotific = (item) => {
    setValue(item.value);
    setLabel(item.label);
    if(onChange){
      onChange(item.value, name );
    }
  }

 
  return (
      
    <div className='content-select-tabs'>
      <div className={`select ${show ? 'open' : ''}`} onClick={() => setShow(!show)}>{Util.getMessage(label)}</div>
      {show ? (
        <div className='select-options'>
          {values && values.map((val, index) => (
            <div key={index}>
              {(!val.not || val.not.toUpperCase().indexOf(upperTheme) === -1 ) && (!val.in || val.in.toUpperCase().indexOf(upperTheme) !== -1 ) ? (
                <div className={"cont-show " + (val.value === selected ? 'active' : '')} onClick={() => {setValueNotific(val);setShow(!show);}}>
                  <label>{Util.getMessage(val.label)}</label>
                  <p className='description' dangerouslySetInnerHTML={{__html: Util.getMessage(`${val.label}.description`)}} />
                </div>
              ) : null}  
            </div>
          ))}
        </div>
      ) :  null}
    </div>
);
}

export default SelectDescriptions;