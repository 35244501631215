import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './../components/auth/Auth';
import  * as Util from './../data/General';

import { connect } from 'react-redux';

class Loader extends Component {
  state ={
    isShowLogin: false
  }
  componentDidMount() {
    window.timeoutLoader = setTimeout(function(){
      this.setState({isShowLogin: true});
    }.bind(this), 5000);
  }
  componentWillUnmount(){
    clearTimeout(window.timeoutLoader);
  }

  handleClick = () => {
    window.location.href = '/';
  }

  render() {
    const { isShowLogin } = this.state;
    if(!isShowLogin){
      return (
        <div className="presentacion" >
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      );
    }else{
      return (
        <div className="presentacion-login">
          <h1>Revie</h1>
          <div>
            <a href='https://admin.shopify.com/'>Shopify</a>
            <a href='https://tiendanube.triciclogo.com/view/'>Tienda Nube</a>
            <a href='https://vtex.triciclogo.com/view/'>VTEX</a>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
    user: state.user,
    shop: state.shop,
});
  
const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader));
